.child-details .child-det {
	padding: 30px;
	background: white;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
	border-radius: 20px;
}

.child-details .child-det .child-info div {
	font-size: 18px;
	margin: 10px;
	font-weight: 600;
	text-transform: capitalize;
}
.child-details .child-det .child-info div span {
	font-weight: 400;
	opacity: 0.5;
}

.child-info {
	display: grid;
	grid-template-columns: 1fr;
}

.info {
	display: grid;
	grid-template-columns: 1fr;
}

.section-head {
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	padding-bottom: 5px;
	border-bottom: 2px solid #ddd;
}

.child-details .con-det {
	padding: 30px;
	background: white;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
	margin-left: 25px;
	border-radius: 20px;
}

.con-det .info div {
	font-size: 18px;
	font-weight: 600;
	margin: 10px;
}

.con-det .info div span {
	font-weight: 400;
	opacity: 0.5;
}

.business-owner-home {
	background-color: #ffffff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	margin-bottom: 24px;
}

.business-owner-home h2 {
	font-size: 18px;
	margin: 0;
	font-weight: 500;
	margin-bottom: 10px;
}

.bussiness-owner-det .info div {
	font-size: 18px;
	font-weight: 600;
	margin: 10px;
}
.business-owner-home .bussiness-owner-det .info {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.employee-list {
	margin-top: 20px;
	background-color: #ffffff;
	border-radius: 20px;
	padding: 20px;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
}

.roles-section {
	background-color: white;
	border-radius: 20px;
	padding: 20px;
	margin-top: 20px;
}

.business-owner-home .bussiness-owner-det .info div span {
	font-weight: 400;
	opacity: 0.5;
}

.business-owner-home .bussiness-owner-det {
	padding: 30px;
	background: white;
	border-radius: 20px;
	margin-top: 20px;
}

.bussiness-owner-det .assign-bo {
	text-align: center;
	margin: 20px;
	font-size: 20px;
	font-weight: 600;
}

.bussiness-owner-det .assign-bo button {
	margin: 10px;
	font-size: 20px;
	background: #ffffff;
	border: 2px solid var(--color-primary);
	border-radius: 10px;
	cursor: pointer;
	padding: 10px;
}

.bussiness-owner-det .assign-bo button:hover {
	margin: 10px;
	font-size: 20px;
	background: var(--color-primary);
	border: 2px solid var(--color-primary);
	color: #ffffff;
	border-radius: 10px;
	cursor: pointer;
}

.child-cont {
	display: flex;
	gap: 20px;
}

.child-cont .child {
	width: 150px;
	margin: 20px;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: white;
	padding: 20px;
	font-size: 18px;
	font-weight: 600;
	word-break: keep-all;
	border: 2px solid var(--color-primary);
	border-radius: 20px;
	color: black;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px; */
}

.child-cont .child:hover {
	background-color: var(--color-primary);
	color: white !important;
}

.employees-in-child {
	border-radius: 10px;
	margin: 20px;
	height: 200px;
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	text-align: center;
	font-size: 25px;
}

.employees-in-child img {
	height: 200px;
	width: 200px;
	border-radius: 20px;
}

.employee-modal,
.role-modal {
	/* width: max-content !important; */
	margin: 0px auto !important;
}

.employees-in-child:hover {
	transform: scale(1.05);
	cursor: pointer;
}

.action-item {
	width: 320px;
	height: 160px;
	margin: 10px;
	background-color: var(--color-bg);
	border-radius: 10px;
	padding: 15px;
	color: black;
	transform: scale(1);
	/* background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%); */
	background-color: #fcfcfc;
	border: 1px dashed purple;
}

.action-item:nth-child(even) {
	/* background-color: #d0d0d0; */
}

.action-item:hover {
	cursor: pointer;
	transform: scale(1.02);
}

.action-item .action-item-header {
	font-size: 18px;
	padding-bottom: 5px;
	font-weight: 600;
	color: purple;
	/* margin: 10px;
	border-bottom: 1px solid var(--color-primary); */
}

.action-item .action-item-body {
	font-size: 12px;
}

.employee-modal {
	padding: 20px;
	max-width: 800px;
	margin: 0 auto;
}


.employee-modal.ant-modal{
	top:0 !important;
}

.section-head {
	/* text-align: center; */
	font-size: 1.5em;
	font-weight: bold;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}

.bussiness-owner-det {
	margin-top: 20px;
}

.assign-bo {
	text-align: center;
	margin-top: 20px;
	padding: 15px;
	background-color: #f9f9f9;
	border-radius: 5px;
	border: 1px solid #e0e0e0;
}

.info {
	margin-top: 16px;
	padding: 8px;
	background-color: #f9f9f9;
	border-radius: 5px;
	border: 1px solid #e0e0e0;
	display: flex;
	flex-direction: column;
	gap: 0px;
	font-size: 14px;
}

.info div {
	display: flex;
	justify-content: space-between;
}

.info span {
	font-weight: normal;
}

.assign-bo button {
	background-color: #007bff;
	color: white;
	padding: 8px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	margin-top: 10px;
}

.assign-bo button:hover {
	background-color: #0056b3;
}

.assign-bo span {
	font-weight: bold;
}
