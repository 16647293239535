.payroll-user-main {
	min-height: 100%;
	padding: 20px;
	border-radius: 20px;
}

.payroll-user-main .selections {
	background-color: white;
	max-width: 525px;
	display: flex;
	flex-wrap: wrap;

	flex-direction: column;
	padding: 20px;
	border-radius: 20px;
	padding: 40px;
	margin: 0 auto;
	transform: translate(0%, 20%);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.payroll-user-main .selections h1 {
	font-size: 24px;
	margin: 0px auto;
	padding: 0px;
	font-weight: 650;
	color: #17599b;
}

.payroll-user-main .selections input {
	width: 100% !important;
	height: 40px;
	background-color: #eee;
}

.payroll-user-main .selections .ant-select-selector {
	width: 450px;
	height: 40px;


	background-color: #eee;
}
.payroll-user-main .selections label {
	margin: 20px 0px 10px 0px;
}

.payroll-user-main .selections button {
	width: 100% !important;
	height: 40px;
	background-color: var(--color-primary);
	opacity: 0.8;
	margin-top: 20px;
}

.payroll-user-main .selections button:hover {
	width: 100% !important;
	height: 40px;
	background-color: var(--color-primary);
	opacity: 1;
}

.admin-payroll-main .pay-btn {
	width: 200px;
	left: calc(100vw - 240px);
	height: 40px;
	background-color: var(--color-primary);
	color: white;
	font-size: 16px;
	margin: 20px auto;
	border: none;
}

.admin-payroll-main .pay-btn:hover {
	width: 200px;
	left: calc(100vw - 240px);
	height: 40px;
	background-color: var(--color-primary);
	color: white !important;
	border: none;
	font-size: 16px;
	margin: 20px auto;
	transform: scale(1.1);
}

.pay-btns {
	display: flex;
	margin: 20px auto;
	justify-content: space-between;
	align-items: center;
}

.pay-btns button {
	height: 40px;
	font-size: 16px;
}

@media print {
	* {
		visibility: hidden;
	}
	.content {
		top: 0;
		left: 0;
		width: 100%;
		height: max-content;
		margin: 0;
		padding: 0;
	}
	.payslip-html {
		margin: 0px;
	}
	.payslip-html * {
		visibility: visible;
	}
	.payslip-html {
		position: relative;
		top: 0;
		left: 0;
		padding: 0;
	}
	@page {
		margin: 0cm;
	}
}

@media (max-width: 768px) {
	.payroll-user-main .selections {
		max-width: 300px;
		padding: 20px;
	}
	.payroll-user-main .selections .ant-select-selector {
		max-width: 250px;
	}
}

.export_pay-container {
	padding: 20px;
}

.export_pay-back-button {
	margin-bottom: 20px;
}

.export_pay-controls {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	width: max-content;
	margin: 0 auto;
}

.ant-table{
	overflow: auto !important;
	padding-inline: 40px !important;
}


.export_pay-export-button {
	/* margin: 0 auto; */
}

.export_pay-data-display {
	margin-top: 20px;
}

.export_pay-download-button {
	margin-bottom: 10px;
}

.export_pay-table {
	margin-top: 10px;
}
