.profile-container {
	width: 100%;
	margin: 0 auto;
	padding: 20px;
	border-radius: 8px;
}

.cover-photo {
	width: 100%;
	height: 200px;
	background: #360033;
	background: -webkit-linear-gradient(to right, #360033, #0b8793);
	background: linear-gradient(to right, #360033, #0b8793);
	position: relative;
	border-radius: 8px 8px 0 0;
	overflow: hidden;
}

.edit-cover-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	border: none;
}

.head-sec {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	border-radius: 8px;
	overflow: hidden;
}

.profile-header {
	display: flex;
	align-items: flex-start;
	padding: 20px;
	padding-top: 0px;
	border-bottom: 1px solid #f0f0f0;
	background-color: #fff;
}

.profile-avatar {
	position: relative;
}

.profile-avatar img {
	margin-top: -50px;
	width: 120px;
	height: 120px;
	object-fit: cover;
	border-radius: 50%;
	border: 2px solid white;
}

.edit-avatar-btn {
	position: absolute;
	bottom: 4px;
	right: 4px;
	background-color: rgba(0, 0, 0, 0.3);
	color: white;
	border: none;
	z-index: 999;
}

.profile-info {
	margin-top: 0px;
	margin-left: 24px;
}

.profile-info div {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}
.profile-info .profile-role {
	text-transform: capitalize;
}
.profile-info h1 {
	margin: 0;
	margin-top: 8px;
	font-size: 24px;
	font-weight: 500;
}

.profile-info p {
	margin: 4px 0;
	color: #777;
}

.profile-tabs {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	border-radius: 8px;
	margin-top: 20px;
	background-color: #fff;
}

.profile-tabs .ant-tabs-nav::before {
	display: none;
}

.profile-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list {
	display: flex;
	flex-wrap: wrap;
	border-bottom: none;
	gap: 8px;
	margin: 16px;
	margin-bottom: 0px;
}

.profile-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list div {
	width: 120px;
	text-align: center;
	border-radius: 8px !important;
}

.profile-tabs .ant-tabs-tab {
	background-color: #f0f0f0 !important;
}

.profile-tabs .ant-tabs-tab.ant-tabs-tab-active {
	background-color: var(--color-primary) !important;
	color: #fff !important;
	border-bottom: none;
}

.profile-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: white;
	border-bottom: none;
}

.profile-tabs .ant-tabs-ink-bar {
	background-color: var(--color-primary);
}

.profile-modal .image-upload {
	width: 100%;
	padding: 0px;
	border-radius: 8px;
	margin-top: 24px;
	margin-bottom: 0px;
}

.profile-modal .ant-upload-drag-icon {
	font-size: 48px;
	color: var(--color-primary);
}

.profile-modal .ant-upload-text {
	font-size: 16px;
	color: #333;
}

.profile-modal .ant-upload-hint {
	font-size: 14px;
	color: #777;
}

@media (max-width: 678px) {
	.profile-header {
		flex-direction: column;
		align-items: center;
	}
	.profile-info div {
		gap: 4px;
		justify-content: center;
	}
	.profile-info h1 {
		text-align: center;
	}
	.profile-container {
		padding: 0;
	}
	.cover-photo {
		height: 160px;
	}
	.profile-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list div {
		width: 120px;
		font-size: 12px;
		text-align: center;
		border-radius: 8px !important;
	}
}
