.ips-sec-main{
    width: 80%;
    padding: 20px;
    margin: 0px;
    border-radius: 10px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

.ips-sec-main .existing-list,.ips-sec-main .add-id-form{
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.ips-sec-main .sec-head{
	width: 100%;
	font-size: 22px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid black;
}

.ips-sec-main .existing-list li{
    text-transform: capitalize;
}

.ips-sec-main .add-id-form .ant-input {
	padding: 8px;
	border: 1px solid #ccc;
    background-color: #f0f0f0;
    width: 300px;
	border-radius: 4px;
}

.ips-sec-main .add-id-form .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ips-sec-main .add-id-form .form button{
    height: 40px;
    width: 150px;
    font-size: 16px;
    background-color: var(--color-primary);
}

.ips-sec-main .add-id-form .form button:hover{
    height: 40px;
    width: 150px;
    font-size: 16px;
    background-color: var(--color-primary);
}

.ips-sec-main .excep-link{
    float: right;
    font-size: 16px;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    padding: 4px 12px;
    border-radius: 5px;
}

.ip-res{
    margin: 20px;
    /* margin: 0 auto; */
    width: max-content;
}

.iprestrict-main{
    background-color: #fff;
    padding: 16px;
    border-radius: 5px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

.iprestrict-main button{
    height: 30px;
    width: 100px;
    font-size: 14px;
    background-color: var(--color-primary);
    color: white;
    margin: 0 auto;
}


.iprestrict-main button:hover{
    height: 40px;
    width: 150px;
    font-size: 16px;
    color: white !important;
    border: none !important;
    background-color: var(--color-primary) !important;
}