.section-1 {
	padding: 25px;
	background-color: white;
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	gap: 20px;
}

.section {
	margin-bottom: 20px;
}

.section-1 .left-sec {
	margin: 20px;
	display: flex;
	justify-content: center;
	/* align-items: baseline; */
    /* flex-direction: row; */
	gap: 5px;
}

.section-1 .left-sec .user-details {
	line-height: 10px !important;
    text-align: center;
}
.section-1 .left-sec .user-details h2 {
	line-height: 1.2 !important;
}

.section-1 .left-sec .user-details h3 {
	opacity: 0.8;
	font-weight: 600;
}

.section-1 .left-sec img {
	border-radius: 50%;
	height: 120px;
	width: 120px;
}

.section-1 .left-sec button {
	position: relative;
	left: -30px;
	top: -15px;
}

.section-1 .left-sec .ant-btn.ant-btn-icon-only .anticon {
	font-size: 16px;
}

.section-1 .right-sec {
	background-color: #eee;
	padding: 25px 70px;
	border-radius: 10px;
	width: 500px;
}

.section-1 .right-sec div {
	font-size: 16px;
	font-weight: 400;

	word-wrap: break-word;
}

.section-1 .right-sec span {
	font-weight: 600;
	/* display: block; */
}

.section-head {
	font-size: 24px;
	margin: 10px;
	font-weight: 600;
}

@media (max-width: 767px) {
	.section-1 {
		padding: 25px;
		background-color: white;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}
	.section-1 .right-sec {
		margin-right: 0px;
		background-color: #eee;
		padding: 20px;
		border-radius: 10px;
		width: calc(100vw - 100px);
	}
	.section-1 .left-sec {
		margin: 0px;
		display: block;
		text-align: center;
		margin-bottom: 30px;
	}
	.section-1 .left-sec img {
		border-radius: 50%;
		height: 150px;
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: -20px;
	}
	.section-1 .left-sec button {
		position: relative;
		left: 50px;
		top: -10px;
	}
}

.workingdays-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.workingdays-go-back {
    margin-bottom: 16px;
}

.workingdays-title {
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: 600;
}

.workingdays-filters {
    margin-bottom: 24px;
}

.workingdays-filter-form {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.workingdays-filter-select {
    width: 150px;
}

.workingdays-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.workingdays-card {
    flex: 1 1 calc(33.333% - 16px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    transition: box-shadow 0.3s ease;
}

.workingdays-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
