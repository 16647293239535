.super-user-home {
    width: 100%;
}

.super-user-home .organization-details{
    /* height: 300px; */
    display: grid;
    grid-template-columns: 2fr 1fr;
}
.super-user-home .second-section{
    /* height: 300px; */
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 0px;
    margin-top: 20px;
}



.super-user-home .organization-details .org-det{
    padding: 30px;
    background: white;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
    border-radius:20px;
}

.super-user-home .organization-details .org-det .org-info div{
    font-size: 18px;
    margin: 10px;
    font-weight: 600;
    text-transform: capitalize;
}
.super-user-home .organization-details .org-det .org-info div span{
    font-weight: 400;
    opacity: 0.5;
}


.super-user-home .org-info{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.super-user-home .info{
    display: grid;
    grid-template-columns: 1fr;
}

.super-user-home .section-head{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 5px;
    border-bottom: 2px solid #ddd;
}

.super-user-home .organization-details .con-det{
    padding: 30px;
    background: white;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
    margin-left: 25px;
    border-radius:20px;
}

.super-user-home .con-det .info div {
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
}

.super-user-home .con-det .info div span{
    font-weight: 400;
    opacity: 0.5;
}


.super-user-home .second-section .plan-details{
    padding: 30px;
    background: white;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
    border-radius:20px;
}

.super-user-home .plan-details .info div {
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
}

.super-user-home .plan-details .info div span{
    font-weight: 400;
    opacity: 0.5;
}

.super-user-home .second-section .child-details{
    padding: 30px;
    background: white;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
    margin-left: 25px;
    border-radius:20px;
}

.super-user-home .child-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.super-user-home .child-info .child{
    width: 150px;
    margin: 20px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    word-break: keep-all;
    border : 2px solid var(--color-primary);
    border-radius : 20px;
    color: black;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px; */
}

.super-user-home .child-info .child:hover{
    background-color: var(--color-primary);
    color: white !important;
}


.super-user-home .child-details .add-new{
    cursor: pointer;
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    width: max-content;
    margin: auto 15px;
}

.super-user-home .child-details .add-new button{
    color: black;
    font-size: 20px;
    padding: 5px;
    height: 50px;
    font-weight: 600;
}

.super-user-home .child-details .add-new:hover{
    background-color: var(--color-primary);
    color: white !important;
}
.super-user-home .child-details .add-new button:hover{
    background-color: var(--color-primary);
    color: white !important;
}

@media (max-width:500px){
    .super-user-home .organization-details{
    display: block;
}
.super-user-home .second-section{
    display: block;
    margin-top: 20px;
}

.super-user-home .organization-details .org-det{
    padding: 20px;
    border-radius:15px;
}
.super-user-home .organization-details .org-det .org-info div{
    font-size: 16px;
    margin: 10px;
    font-weight: 600;
    text-transform: capitalize;
}
.super-user-home .organization-details .con-det{
    padding: 20px;
    border-radius:15px;
    margin-left: 0px;
    margin-top: 20px;
}
.super-user-home .org-info{
    display: grid;
    grid-template-columns: 1fr;
}
.super-user-home .plan-details .info div {
    font-size: 16px;
}
.super-user-home .con-det .info div {
    font-size: 16px;
}
.super-user-home .second-section .child-details{
    padding: 20px;
    margin-left: 0px;
    margin-top: 20px;
}
.super-user-home .child-info .child{
    width: 100px;
    height: 100px;
    margin: 10px;
    overflow: hidden;
    line-break: loose;
    padding: 10px;
    font-size: 12px;
}
.super-user-home .child-details .add-new{
    font-size: 14px;
    margin-top: 20px;
    height: 50px;
    margin: auto auto;
}
}