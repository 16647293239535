.assets_main__container {
	max-width: 1200px;
	margin: 24px auto;
	padding: 30px;
	background-color: #ffffff;
	border-radius: 12px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.assets_main__header {
	display: flex;
	align-items: center;
    gap: 24px;
	margin-bottom: 40px;
}

.assets_main__back-button {
	display: flex;
	align-items: center;
	background-color: var(--color-primary);
	color: white;
	border: none;
	border-radius: 8px;
	padding: 8px 16px;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
}

.assets_main__back-button:hover {
	background-color: var(--color-primary);
}

.assets_main__title {
	font-size: 24px;
	font-weight: 600;
	margin: 0;
}

.assets_main__add-button {
	background-color: green;
	color: white;
	border: none;
    float: right;
	border-radius: 8px;
	font-weight: 500;
	font-size: 16px;
	height: 40px;
	padding: 0 20px;
	cursor: pointer;
	transition: all 0.3s ease;
}

.assets_main__add-button:hover {
	background-color: #73d13d;
}

.assets_main__table-wrapper {
	margin-bottom: 60px;
	padding: 20px;
	border-radius: 8px;
}

.assets_main__table-title {
	font-size: 18px;
	font-weight: 500;
	color: var(--color-primary);
	margin-bottom: 16px;
    text-decoration-line: underline;
}

.assets_main__table {
	border-radius: 8px;
	overflow: auto;
}

.assets_main__table .ant-table-thead > tr > th {
	background-color: #e8e8e8;
	font-weight: 500;
	font-size: 16px;
	color: #000;
}

.assets_main__table .ant-table-tbody > tr:hover {
	background-color: #e6f7ff;
	transition: background-color 0.3s ease;
}

.assets_main__modal {
	max-width: 600px;
}

.assets_main__modal .ant-modal-content {
	border-radius: 12px;
	padding: 24px;
}

.assets_main__modal .ant-modal-header {
	background-color: #fafafa;
	border-bottom: none;
	border-radius: 12px 12px 0 0;
}

.assets_main__modal .ant-modal-title {
	font-size: 22px;
	font-weight: 700;
	color: #333333;
}

.assets_main__form-item {
	margin-bottom: 24px;
}

.assets_main__form-item label {
	font-weight: 600;
	color: #595959;
	font-size: 14px;
}

.assets_main__form-item .ant-input,
.assets_main__form-item .ant-select-selector {
	height: 44px;
	border-radius: 6px;
	border: 1px solid #d9d9d9;
	transition: border-color 0.3s ease, box-shadow 0.3s ease;
	font-size: 16px;
}

.assets_main__form-item .ant-input:focus,
.assets_main__form-item .ant-input:hover,
.assets_main__form-item .ant-select-focused .ant-select-selector {
	border-color: #1890ff;
	box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.3);
}

.assets_main__form-item .ant-select-selector {
	display: flex;
	align-items: center;
	height: 44px;
}

.assets_main__modal .ant-btn-primary {
	background-color: #1890ff;
	border-color: #1890ff;
	border-radius: 8px;
	height: 45px;
	font-size: 16px;
	font-weight: 600;
	transition: all 0.3s ease;
	box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
}

.assets_main__modal .ant-btn-primary:hover {
	background-color: #40a9ff;
	border-color: #40a9ff;
	transform: translateY(-2px);
	box-shadow: 0 6px 14px rgba(24, 144, 255, 0.5);
}

.assets_main__modal .ant-btn-default {
	background-color: #f5f5f5;
	color: #333333;
	border-radius: 8px;
	height: 45px;
	font-size: 16px;
	font-weight: 600;
	transition: background-color 0.3s ease;
}

.assets_main__modal .ant-btn-default:hover {
	background-color: #e8e8e8;
}





.assetdetails__container {
	max-width: 1200px;
	margin: 20px auto;
	padding: 30px;
	background-color: #ffffff;
	border-radius: 12px;
	box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
}

.assetdetails__header {
	display: flex;
	align-items: center;
	gap: 24px;
	margin-bottom: 40px;
}

.assetdetails__title {
	font-size: 20px;
	color: #333333;
	font-weight: 600;
	margin: 0;
}

.assetdetails__add-button {
	background-color: green;
	color: white;
	border: none;
    float: right;
	border-radius: 8px;
	font-weight: 500;
	font-size: 16px;
	height: 40px;
	padding: 0 20px;
	cursor: pointer;
}

.assetdetails__add-button:hover {
	background-color: #73d13d;
}

.assetdetails__table-wrapper {
	margin-bottom: 60px;
	padding: 20px;
	border-radius: 8px;
}

.assetdetails__table-title {
	font-size: 18px;
	font-weight: 600;
	color: #000;
	margin-bottom: 16px;
}

.assetdetails__table {
	border-radius: 8px;
	overflow: auto;
}

.assetdetails__table .ant-table-thead > tr > th {
	background-color: #e8e8e8;
	font-weight: 600;
	font-size: 16px;
	color: #000;
}

.assetdetails__table .ant-table-tbody > tr:hover {
	background-color: #e6f7ff;
	transition: background-color 0.3s ease;
}

.assetdetails__modal {
	max-width: 600px;
}

.assetdetails__modal .ant-modal-content {
	border-radius: 12px;
	padding: 24px;
}

.assetdetails__modal .ant-modal-header {
	background-color: #fafafa;
	border-bottom: none;
	border-radius: 12px 12px 0 0;
}

.assetdetails__modal .ant-modal-title {
	font-size: 22px;
	font-weight: 700;
	color: #333333;
}

.assetdetails__form-item {
	margin-bottom: 24px;
}

.assetdetails__form-item label {
	font-weight: 600;
	color: #595959;
	font-size: 14px;
}

.assetdetails__form-item .ant-input,
.assetdetails__form-item .ant-input-textarea {
	border-radius: 6px;
	border: 1px solid #d9d9d9;
	transition: border-color 0.3s ease, box-shadow 0.3s ease;
	font-size: 16px;
}

.assetdetails__modal .ant-btn-primary {
	background-color: #1890ff;
	border-color: #1890ff;
	border-radius: 8px;
	height: 45px;
	font-size: 16px;
	font-weight: 600;
	transition: all 0.3s ease;
	box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
}

.assetdetails__modal .ant-btn-primary:hover {
	background-color: #40a9ff;
	border-color: #40a9ff;
	transform: translateY(-2px);
	box-shadow: 0 6px 14px rgba(24, 144, 255, 0.5);
}

.assetdetails__modal .ant-btn-default {
	background-color: #f5f5f5;
	color: #333333;
	border-radius: 8px;
	height: 45px;
	font-size: 16px;
	font-weight: 600;
	transition: background-color 0.3s ease;
}

.assetdetails__modal .ant-btn-default:hover {
	background-color: #e8e8e8;
}
