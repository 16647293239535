.home-profile {
	border-radius: 20px;
	padding: 20px;
}

.main-home-section-1 {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	margin-bottom: 20px;
}

.main-home-section-1 .pre-abs {
	display: flex;
	flex-direction: row;
	gap: 10px;
}


.main-home-section-1 .profile-img img {
	height: auto;
	width: 250px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
}

.main-home-section-1 .pre-abs .ant-card {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	height: max-content;
}

.main-home-section-2 {
	margin-top: 20px;
	width: 50%;
}

.main-home-section-2 .ant-collapse-header-text {
	font-weight: 600;
	font-size: 16px;
}

.quote {
	max-width: 420px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	padding: 20px;
	border-radius: 10px;
	background-color: #FFFFFF;
	height: max-content;
	background: linear-gradient(90deg, #fcff9e 0%, #c67700 100%);
}

.quote .quote-bg-img{
	position: absolute;
	height:250px;
	width: 350px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.quote .quote-cont h2 {
	font-size: 20px;
	padding-bottom: 10px;
	border-bottom: 1px solid #e8e8e8;
	margin-bottom: 5px;
	font-weight: 600;
	color: var(--color-primary);
}

.quote .quote-cont .quote-content {
	font-family: cursive;
	font-size: 15px;
	z-index: 999;
	position: relative;
	min-height: 145px;
	max-height: max-content;
}

.quote-image{
	height: 150px;
	width: 150px;
	float: right;
	margin-top: -75px;
	margin-right: -25px;
}

.quote-image {
	background-color: transparent !important;
}

.home-profile img {
	height: 400px;
	border-radius: 20px;
}

.popup-trigger {
	cursor: pointer;
	color: blue;
}

.popup-content {
	position: absolute;
	background: white;
	border: 1px solid #ccc;
	padding: 10px;
	z-index: 100;
	width: 200px;
}
.popup-close-button {
	position: fixed;
	top: 10px;
	right: 10px;
	border: 1px solid red;
	border-radius: 50%;
	font-weight: 600;
	padding: 2px 10px;
}

.main-home-section-1 .info-sec {
	padding: 20px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.main-home-section-1 .today-info {
	background: linear-gradient(90deg, #1cb5e0 0%, #000851 100%);
	padding: 20px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	margin-bottom: 10px;
}

.main-home-section-1 .today-info .tit {
	font-size: 20px;
	font-weight: 600;
	color: #ffffff;
}

.main-home-section-1 .today-info .date {
	font-size: 20px;
	color: #ffffff;
}

.greeting {
	font-size: 36px;
	margin-bottom: 20px;
	color: var(--color-primary);
}

.greeting span {
	font-weight: 600;
}

.main-home-section-1 .info-sec div {
	display: flex;
	justify-content: space-between;
	gap: 64px;
	font-size: 16px;
	line-height: 2;
}

.main-home-section-1 .info-sec div span {
	font-weight: 600;
}

.popup-trigger {
	cursor: pointer;
	color: blue;
	text-decoration: underline;
}

.popup-content {
	position: fixed;
	top: 50%;
	left: 50%;
	padding: 20px;
	transform: translate(-20%, -40%);
	background-color: white;
	padding: 20px;
	border: 1px solid #ccc;
	width: 500px;
	border-radius: 10px;
	margin-bottom: auto;
	max-height: 70vh;
	overflow-y: auto;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-close-button {
	cursor: pointer;
	color: red;
	text-align: right;
}
.popup-cont {
	display: flex;
	flex-direction: row;
	margin: 20px;
	position: relative;
}

@media (max-width: 768px) {
	.quote {
		margin-bottom: 20px;
		width: 100% !important;
		max-width: 100%;
	}
	.main-home-section-2 {
		margin-top: 20px;
		width: 100%;
	}
	.home-profile {
		border-radius: 20px;
		padding: 20px;
		flex-direction: column;
		gap: 30px;
		height: calc(100vh - 140px);
	}
	.home-profile img {
		width: calc(100vw - 100px);
		height: auto;
		max-height: 300px;
	}
	.greeting {
		font-size: 26px;
		margin-bottom: 20px;
		color: var(--color-primary);
	}

	.greeting span {
		display: block;
		font-weight: 600;
		word-wrap: break-word;
	}
	.main-home-section-1 .info-sec {
		width: calc(100vw - 40px);
		overflow: auto;
		word-wrap: break-word;
	}
	.main-home-section-1 .info-sec div {
		display: flex;
		flex-direction: column;
		gap: 0;
		font-size: 14px;
	}
	.main-home-section-1 .info-sec span {
		margin-right: 10px;
	}
}

@media (max-width: 450px) {
	.quote {
		margin-bottom: 20px;
		justify-content: center;
		align-items: center;
	}
	.home-profile img {
		width: calc(100vw - 100px);
		height: auto;
		max-height: 150px;
	}
	.main-home-section-1 .profile-img {
		display: flex;
		width: 100%;
		justify-content: center;
	}
}
