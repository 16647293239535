.form12bb-container {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.form12bb-container .print-btn{
    margin: 24px;
    position: relative;
    width: 160px;
    left:calc(100% - 180px);
    background-color: var(--color-primary);
    height: 40px;
}

.form12bb-title {
	text-align: center;
	font-size: 1.8rem;
	margin-bottom: 20px;
	color: #333;
}

.form12bb-section {
	margin-bottom: 24px;
}

.form12bb-section-title {
	font-size: 1.4rem;
	margin-bottom: 16px;
	color: #555;
	border-bottom: 2px solid #ddd;
	padding-bottom: 8px;
}

.form12bb-item {
	margin-bottom: 16px;
}

.form12bb-input {
	font-size: 1rem;
	height: 44px;
	width: 100%;
}

.form12bb-textarea {
	font-size: 1rem;
	width: 100%;
}

.form12bb-submit-btn {
	width: 100%;
	height: 48px;
	font-size: 1.1rem;
	background-color: #1890ff;
	color: white;
	border-radius: 4px;
	border: none;
}

.form12bb-submit-btn:hover {
	background-color: #40a9ff;
	color: white;
}

.form12bb-loading {
	text-align: center;
	margin-top: 50px;
}

@media (max-width: 768px) {
	.form12bb-container {
		padding: 15px;
	}

	.form12bb-submit-btn {
		font-size: 1rem;
		height: 42px;
	}

	.form12bb-input {
		height: 40px;
	}
}

@media (max-width: 576px) {
	.form12bb-item {
		width: 100%;
	}
}

@media print {
	* {
		visibility: hidden;
	}
	.content {
		top: 0;
		left: 0;
		width: 100%;
		height: max-content;
		margin: 0;
		padding: 0;
	}
	.form-12B-form-container {
		margin: 0px;
	}
	.form-12B-form-container * {
		visibility: visible;
	}
	.form-12B-form-container {
		position: relative;
		top: 0;
		left: 0;
		padding: 0;
	}
	@page {
		margin: 0cm;
	}
}
