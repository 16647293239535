.registration-container {
	background-image: url("./../../Images/Register\ page\ -without\ logo.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	width: 100vw;
	display: grid;
	grid-template-columns: 1fr 1fr;
	overflow: auto;
}

.registration-container .right-cont {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.registration-container .left-cont {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.registration-container .left-cont img {
	width: 600px;
}

@media (max-width: 767px) {
	.registration-container .left-cont img {
		width: 250px;
    height: auto;
	}
}

.registration-container .step-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.registration-container .step-content .form-container {
	display: flex;
	flex-direction: column;
	background-color: white;
	width: max-content;
	height: max-content;
	padding: 20px;
	border-radius: 10px;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
}

.registration-container .step-content .form-container label {
	font-size: 14px;
	margin: 5px;
}

.registration-container .form-container .input-field {
	width: 300px;
	margin: 5px;
	height: 35px;
	border: 0;
	border-radius: 5px;
	border: 2px solid #ddd;
}

.registration-container .steps-action button {
	width: 120px;
	height: 40px;
	margin: 15px;
	border: 2px solid rgba(0, 23, 68, 1);
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
	background-color: transparent;
	font-size: 18px;
	color: rgba(0, 23, 68, 1);
	font-weight: 700;
}

.registration-container .steps-action button:hover {
	width: 120px;
	height: 50px;
	margin: 15px;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
	background: rgba(0, 23, 68, 1);
	font-size: 20px;
	color: white;
	font-weight: 700;
}

.registration-container .step-number {
	font-size: 24px;
	font-weight: 700;
	color: white;
	margin: 15px;
}

.registration-container .form-container .feature-checkbox {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.registration-container .feature-checkbox .plan-name {
	font-size: 18px;
	font-weight: 800;
}

.registration-container .checkbox .feature {
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
}

.col-green {
	color: green;
}

.col-red {
	color: red;
}
.registration-container .ant-radio-inner {
	border-color: #000;
}

@media (max-width: 500px) {
	.registration-container {
		background-image: url("./../../Images/Register\ page\ -without\ logo.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		display: block;
		overflow: auto;
	}

	.registration-container img {
		width: 150px;
		height: 100px;
	}
	.registration-container .form-container .input-field {
		width: calc(100vw - 100px);
		margin: 5px;
		height: 30px;
		border: 0;
		border-radius: 5px;
		border: 2px solid #ddd;
	}
	.registration-container .step-number {
		font-size: 20px;
	}
	.registration-container .form-container .feature-checkbox {
		display: flex;
		flex-direction: column;
	}
	.registration-container .checkbox .feature {
		width: calc(100vw - 120px);
	}
	.registration-container .feature-checkbox .plan-name {
		font-size: 16px;
		font-weight: 800;
	}
	.registration-container .feature-checkbox .feature p {
		font-size: 12px;
	}
	.registration-container .amount-cont {
		width: calc(100vw - 80px);
		font-size: 13px;
	}
}

.payment-container {
	height: 100vh;
	width: 100vw;
	display: grid;
	grid-template-columns: 1fr 1fr;
	overflow: auto;
}

.payment-container .right-cont {
	background-image: url("./../../Images/payment.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	z-index: 1;
}
.payment-container .right-cont img {
	height: 200px;
	width: 260px;
	z-index: 2;
}

/* .payment-container .right-cont .color-purple{
  background-color: #001744;
  height: 100%;
  width: calc(100% - 5px);
  opacity: 0.6;
  position: relative;
  top: -204px;
  z-index: -1;
} */

.payment-container .left-cont {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.payment-container .left-cont .pricing-card {
	width: calc(100% - 300px);
}

.payment-container .left-cont .pricing-card .ant-card-head-title {
	font-size: 24px;
	text-transform: uppercase;
}

.payment-container .left-cont .feature-details .detail {
	display: flex;
	justify-content: space-between;
	font-size: 20px;
	padding: 10px;
	border-top: 2px solid #001744;
}

.payment-container .left-cont .feature-details {
	background: #eee;
	/* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 30px; */
	border-radius: 20px;
	padding: 30px;
}

.col-green {
	color: rgba(61, 177, 42, 1);
}

.text-up {
	text-transform: uppercase;
}

.payment-container .proceed-text {
	margin: 50px;
	font-size: 16px;
	text-align: center;
}

.payment-container .payment-btn {
	width: 200px;
	height: 59px;
	font-size: 20px;
	border: 2px solid rgba(61, 177, 42, 1);
	color: rgba(61, 177, 42, 1);
	font-weight: 600;
}

.payment-container .payment-btn:hover {
	width: 200px;
	height: 59px;
	font-size: 20px;
	background-color: rgba(61, 177, 42, 1);
	border: 2px solid rgba(61, 177, 42, 1);
	color: #ffffff;
	font-weight: 600;
}

.logo-mb {
	display: none;
}

@media (max-width: 500px) {
	.payment-container {
		height: 100vh;
		width: 100vw;
		display: block;
	}
	.payment-container .right-cont {
		display: none;
	}
	.payment-container .left-cont {
		background-image: url("./../../Images/payment.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		height: 100vh;
		width: 100vw;
	}

	.payment-container .left-cont .pricing-card {
		width: calc(100% - 50px);
	}

	.payment-container .left-cont .pricing-card .ant-card-head-title {
		font-size: 18px;
		text-transform: uppercase;
	}

	.payment-container .left-cont .feature-details .detail {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		padding: 10px;
		border-top: 2px solid #001744;
	}

	.payment-container .left-cont .feature-details {
		background: #eee;
		/* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 30px; */
		border-radius: 20px;
		padding: 30px;
	}
	.logo-mb {
		display: block;
		height: 150px;
		width: 200px;
	}
	.payment-container .proceed-text {
		margin: 20px;
	}
}
