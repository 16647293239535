
.child-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.section-head {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.loading-message,
.error-message {
    font-size: 1.2rem;
    color: #666;
}

.child-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.child-item {
    background-color: var(--color-primary);
    color: white;
    padding: 15px;
	height: 100px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
}

.child-item:hover {
    background-color: var(--color-primary);
	transform: scale(1.01);
}

@media (min-width: 768px) {
    .child-list {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .child-item {
        width: calc(33.333% - 10px);
        min-width: 200px;
    }
}
