.add-employee-cont input {
	height: 40px !important;
	width: 350px !important;
	background: #eee;
}

.employeelist-main{
	background-color: white;
	padding: 20px;
	border-radius: 20px;
}

.add-employee-cont {
	margin: 20px;
}

.add-employee-cont .ant-picker-input {
	height: 35px;
	width: 330px;
}

.add-employee-cont .add-emp-form {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

/* .add-employee-cont .add-emp-form button {
	height: 45px;
	border: 2px solid var(--color-primary);
	background-color: #eee;
	color: var(--color-primary);
	font-size: 18px;
	font-weight: 600;
}

.add-employee-cont .add-emp-form button:hover {
	background-color: var(--color-primary);
	color: white;
} */

.toolbar-wrapper {
	position: fixed;
	bottom: 32px;
	left: 50%;
	padding: 0px 24px;
	color: #fff;
	font-size: 20px;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
	padding: 12px;
	cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
	cursor: not-allowed;
	opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
	opacity: 0.3;
}

.employee-search {
	display: flex;
	justify-content: space-between !important;
	margin-bottom: 10px;
}
.employee-search input {
	width: 200px;
	height: 40px;
}

.employee-table.ant-table-wrapper .ant-table td {
	border-bottom: 1px solid black;
	margin: 2px;
	cursor: pointer;
}

@media (max-width: 500px) {
	.add-employee-cont input {
		height: 40px !important;
		width: 250px !important;
		background: #eee;
	}

	.add-employee-cont {
		margin: 20px;
	}
	.add-employee-cont .ant-picker-input {
		height: 35px;
		width: 230px;
	}
	.add-employee-cont .ant-select-selector {
		height: 35px;
		width: 250px !important;
	}
	.documents-main .group {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.documents-main {
		background-color: white;
		padding: 5px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.documents-main .document-item {
		height: 300px;
		width: 230px;
		background-color: #eee;
	}
	

	.documents-main .document-item img {
		height: 200px;
		width: 170px;
	}
}
.employeelist-main {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.go-back {
    margin-bottom: 20px;
}

.employee-search .section-head {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #333;
	border-bottom: none;
}

.employee-search {
    margin-bottom: 20px;
    display: flex;
	align-items: center;
}

.employee-search .ant-input {
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.employee-table {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
}

.employee-table .ant-table-content{
	overflow: auto;
}

.employee-table .ant-table-thead > tr > th {
    background-color: #f5f5f5;
    font-weight: bold;
}

.employee-table .ant-table-tbody > tr > td {
    padding: 16px;
}

.employee-table .ant-table-row:hover {
    background-color: #f0f8ff;
}

.employee-table .ant-btn {
    border-radius: 5px;
    transition: background-color 0.3s;
}

.employee-table .ant-btn:hover {
    background-color: var(--color-primary);
    color: white;
}

.ant-modal-body {
    padding: 20px;
}

.ant-modal-body .detail-label {
    font-weight: bold;
}

.ant-modal-body p {
    margin-bottom: 10px;
    font-size: 16px;
}

@media (max-width: 768px) {
    .section-head {
        font-size: 24px;
    }

    .employee-table .ant-table-tbody > tr > td {
        padding: 12px;
    }

    .ant-modal-body p {
        font-size: 14px;
    }
}

