.add-emp {
}

.add-emp .form-container {
	width: 250px;
	margin: 0 auto;
}

.add-emp .form-container input {
	height: 40px;
	width: 250px;
	background: #eee;
}
.add-emp .form-container .section-head {
	font-size: 20px;
	margin: 15px;
	font-weight: 600;
	border-bottom: 2px solid #eee;
}
.add-emp .ant-select-selector {
	width: 250px !important;
	background-color: #eee;
}

.add-emp label {
	margin-bottom: 20px;
	font-size: 18px;
}

.add-emp button {
	margin: 20px auto;
	border: 2px solid var(--color-primary);
	border-radius: 10px;
	font-size: 18px;
	height: 40px;
	font-weight: 600;
}

.add-emp button:hover {
	background: var(--color-primary);
	color: white;
}

.notifications-container .show-button {
	margin-bottom: 10px;
	padding: 5px 10px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.notifications-container .show-button:hover {
	background-color: #0056b3;
}

.notifications-container .notifications-header {
	font-size: 24px;
	margin-bottom: 10px;
}

.notifications-container .notifications-list {
	margin-top: 20px;
}

.notifications-container .notification {
	border-radius: 5px;
	padding: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	gap: 50px;
	background-color: #eee;
}

.notifications-container .notification.unread {
	background-color: white;
}

.notifications-container .notification-date {
	font-weight: bold;
	margin-bottom: 5px;
}

.notifications-container .notification-message {
	margin-bottom: 5px;
	width: calc(100vw - 625px);
}

.notifications-container .mark-as-read-button {
	padding: 5px 10px;
	background-color: #28a745;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.notifications-container .mark-as-read-button:hover {
	background-color: #218838;
}

.notifications-container .no-notifications {
	font-style: italic;
}

@media (max-width: 767px) {
	.notifications-container .notification-message {
		margin-bottom: 5px;
		font-size: 12px;
	}
	.notifications-container .notifications-header {
		font-size: 20px;
		margin-bottom: 10px;
	}

	.notifications-container .notifications-list {
		margin-top: 10px;
	}
	.notifications-container .notification-date {
		font-weight: bold;
		margin-bottom: 5px;
		font-size: 12px;
	}
	.notifications-container .notifications-list .notification {
		flex-direction: column;
	}
	.notifications-container .notification-message {
		margin-bottom: 5px;
		width: 100%;
	}
	.notifications-container .notification {
		align-items: flex-start;
		gap: 0px;
	}
}

.homepage-container {
	padding: 20px;
}

.title {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 20px;
	color: #1890ff;
}

.summary {
	font-size: 16px;
	margin-bottom: 20px;
}

.module-card {
	margin-top: 20px;
}

.login-button {
	margin-top: 20px;
	text-align: center;
}

@media (max-width: 768px) {
	.module-card {
		margin-top: 10px;
	}
}

.homepage-container svg {
	width: 250px !important;
	height: 250px !important;
}

.loading-video {
	max-width: 400px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
