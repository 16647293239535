.content {
	position: absolute;
	top: 80px;
	left: 225px;
	width: calc(100vw - 225px);
	height: calc(100vh - 80px);
	background: var(--color-bg);
	padding: 20px;
	overflow: auto;
	/* border-top: 1px solid #e8e8e8; */
	/* border-left: 1px solid #e8e8e8; */
	border-top-left-radius: 0px;
}

.ant-layout .ant-layout-sider-zero-width-trigger {
	position: fixed;
	top: 21px;
	background: transparent !important;
	font-size: 25px;
	left: 10px;
	z-index: 5;
	color: black !important;
	left: calc(100vw - 50px);
	margin-left: 5px;
	border-radius: 10px;
}

.menu a {
	text-decoration: none;
}

.head {
	position: fixed;
	top: 0;
	height: 80px;
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 2px solid #e8e8e8;
	background: #fff !important;
}

.side .menu {
	font-size: 14px;
	padding: 0px;
	font-family: var(--font-family-primary);
	background-color: var(--color-primary) !important;
	font-weight: 500;
	opacity: 1;
	border-right: none;
	border-inline-end: none !important;
}

.side .ant-menu {
	background-color: var(--color-primary) !important;
}

.side .ant-menu-item {
	border: none;
	border-radius: 16px;
	height: 48px;
	font-size: 14px;
	margin-inline: 8px !important;
	margin: 0;
	width: calc(100% - 16px) !important;
	/* width: 100% !important; */
	color: #fff;
}

.side .ant-menu-submenu .ant-menu-submenu-title {
	height: 48px;
	border: none;
	font-size: 14px;
	margin-inline: 8px !important;
	width: calc(100% - 16px) !important;
	border-radius: 0;
	color: #fff;
}

.side .ant-menu-submenu .ant-menu-item {
	border: none;
	margin-left: 20px;
	font-size: 10px;
	height: 36px;
	width: calc(100% - 16px) !important;
	/* margin: 0 !important; */
}

.side .ant-menu-item:hover {
	background: rgba(255, 255, 255, 0.2) !important;
}

.side .ant-menu-item-selected {
	background-color: rgba(255, 255, 255, 0.2) !important;
}

.side .ant-menu-submenu .ant-menu-item-selected {
	margin: 0px !important;
}

.side .ant-menu-submenu .ant-menu-item:hover {
	background: rgba(255, 255, 255, 0.2) !important;
}

.header-logo {
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-logoo img {
	height: 80px;
	width: auto;
	margin: 0 auto;
}

.header-logo img {
	height: 120px;
	width: 170px;
	margin: 0 auto;
}

.header-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.header-buttons .anticon svg {
	color: var(--color-primary);
}

.header-buttons img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 1px solid #e8e8e8;
}

.header-buttons img:hover {
	cursor: pointer;
}

.head-icons a:hover {
	color: black;
}

.logoutbtn {
	display: none;
}

.side {
	background-color: var(--color-primary) !important;
}

.ant-dropdown .ant-dropdown-menu {
	width: max-content !important;
}

.tourbtn {
	right: 100px;
	width: 60px;
	height: 60px;
	text-align: left;
}

.msgcount{
	background-color: red;
	padding: 2px 4px;
	border-radius: 50%;
}

@media (max-width: 768px) {
	.ant-layout .ant-layout-sider {
		position: absolute;
		top: 80px !important;
		z-index: 5 !important;
		height: 100vh !important;
	}

	.ant-tour-content {
		margin: 0px !important;
	}
	.ant-tour {
		width: calc(100vw - 100px) !important;
	}
	.tourbtn {
		right: 30px;
		width: 35px;
		height: 35px;
		text-align: left;
		padding: 5px !important;
	}
	.ant-layout .ant-layout-sider-zero-width-trigger {
		color: black !important;
		font-size: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;
		cursor: pointer;
		transition: background 0.3s ease;
	}

	.content {
		position: relative;
		top: 80px;
		left: 0px;
		width: 100vw;
		height: calc(100vh - 80px);
		padding: 20px;
	}

	.head .title {
		margin-left: -30px;
		padding: 0;
		font-size: 22px;
		font-weight: 500;
	}
	.head {
		position: fixed;
		top: 0;
		height: 80px;
		width: 100vw;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background: var(--color-primary);
		z-index: 2;
	}

	.header-logo img {
		height: 80px;
		width: auto;
		margin-top: 0px;
		margin-left: -20px;
	}

	.logoutbtn {
		display: block;
		width: 200px !important;
		margin: 5px;
		border-radius: 7px;
	}

	.logoutbtn button {
		font-size: 16px;
		font-weight: 700;
		height: 37px;
	}

	.header-buttons img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid #e8e8e8;
	}
}
