.chat-header {
	display: flex;
	align-items: center;
	padding: 0 16px;
	background-color: #fff;
	border-bottom: 1px solid #e8e8e8;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	height: 64px;
	line-height: 48px;
}
.chat-header .chat-back-button {
	margin-right: 16px;
}
.chat-header .chat-title {
	flex: 1;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
}
.chat-container {
	display: flex;
	position: relative;
	top: 64px;
	height: calc(100vh - 64px);
	overflow: auto;
}
.chat-sidebar {
	width: 300px;
	background: #fff;
	border-right: 1px solid #e8e8e8;
	padding: 16px;
	position: relative;
}
.chat-sidebar .chat-spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.chat-sidebar .chat-new-chat-button {
	position: absolute;
	bottom: 20px;
	right: 20px;
	background-color: var(--color-primary);
}
.chat-content {
	flex: 1;
	background: #fff;
	padding: 0;
	display: flex;
	flex-direction: column;
}
.chat-content .chat-area {
	flex: 1;
	overflow-y: auto;
}
.chat-content .chat-empty-state {
	text-align: center;
	padding: 50px;
	font-size: 18px;
	color: #888;
}

.chat-window {
	display: flex;
	flex-direction: column;
	height: 100%;
	background: #fff;
}

.chat-window-header {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 16px;
	text-align: center;
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 8px;
}

.chat-messages {
	flex: 1;
	overflow-y: auto;
	padding-right: 8px;
	margin-bottom: 16px;
	padding: 24px;
}

.chat-message {
	display: flex;
	align-items: flex-start;
	margin-bottom: 12px;
}

.chat-message-content {
	margin-left: 12px;
	max-width: 70%;
}

.chat-message-header {
	font-size: 14px;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	gap: 16px;
}

.chat-message-sender {
	color: var(--color-primary);
}

.chat-message-timestamp {
	font-size: 12px;
	color: #aaa;
}

.chat-message-text {
	background: #f2f2f2;
	padding: 8px;
	border-radius: 8px;
	line-height: 1.4;
}

.chat-input {
	display: flex;
	flex-direction: column;
	padding: 24px;
}

.chat-input textarea {
	resize: none;
}

.chat-input button {
	align-self: flex-end;
	margin-top: 10px;
	background-color: var(--color-primary);
}

.recent-container {
	padding: 10px;
}

.recent-participant-item {
	display: flex;
	align-items: center;
	padding: 10px;

	cursor: pointer;
}

.recent-participant-item.active {
	background-color: var(--color-primary);
	font-weight: 400;
	color: #fff;
	border-radius: 10px;
}
.recent-avatar {
	margin-right: 10px;
}

.recent-participant-name {
	font-weight: 500;
}

.recent-participant-item span {
	height: 2px;
	width: 2px;
	background-color: green;
}

.recent-participant-name.seen {
	color: red;
}

.recent-participant-message {
	color: #555;
}

.recent-participant-item.active .recent-participant-message {
	color: #aaa;
}

.chat-message-avatar {
	width: 40px;
	height: 40px;
	background-color: var(--color-primary);
	color: #ffffff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: bold;
	margin-right: 10px;
}

.recent-participant-item.active .chat-message-avatar {
	background-color: #fff;
	color: var(--color-primary);
}





.broadcast-container {
    padding-inline: 24px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 20px auto;
}

.broadcast-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.broadcast-title {
    font-size: 28px;
    font-weight: 500;
    color: var(--color-primary);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.broadcast-button {
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
}

.broadcast-button:hover {
    background-color: var(--color-primary);
    transform: translateY(-1px);
}

.broadcast-list {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.broadcast-list-item {
    border-bottom: 1px solid #e9ecef;
    padding: 15px 0;
    transition: background-color 0.2s;
}

.broadcast-list-item:hover {
    background-color: #f1f1f1;
}

.broadcast-list-item:last-child {
    border-bottom: none;
}

.broadcast-sender {
    font-size: 16px;
    color: var(--color-primary);
    font-weight: 600;
}

.broadcast-content {
    font-size: 14px;
    color: #555;
    margin-top: 0px;
    line-height: 1.5;
}

.broadcast-modal {
    padding: 20px;
}

.broadcast-form {
    display: flex;
    flex-direction: column;
}

.broadcast-textarea {
    resize: none;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #ccc;
    transition: border-color 0.2s;
}

.broadcast-textarea:focus {
    border-color: #007bff;
    outline: none;
}

.broadcast-submit-button {
    margin-top: 15px;
    background-color: #28a745;
    color: white;
    border: none;
	width: 100%;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
}




@media (max-width: 768px) {
    .broadcast-container {
        padding: 15px;
    }

    .broadcast-title {
        font-size: 24px;
    }

    .broadcast-button,
    .broadcast-submit-button {
        font-size: 14px;
        padding: 10px 15px;
    }

    .broadcast-list-item {
        padding: 10px 0;
    }

    .broadcast-textarea {
        font-size: 14px;
    }
}
