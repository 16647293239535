.punch-main {
	border-radius: 15px;
	padding: 20px;
	margin-bottom: 20px;
}

.punch-header {
	font-size: 16px;
	color: #333333;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
}

.punch-data {
	font-size: 14px;
	color: #666666;
	margin-bottom: 20px;
}

.punch-circle {
	position: relative;
	width: 150px;
	height: 150px;
	margin: auto;
	border: 10px solid #d3d3d3;
	border-radius: 50%;
}

.punch-circle .inner-circle {
	/* position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	font-weight: bold;
}

.break-overtime {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	font-size: 14px;
	color: #666666;
}

.break-overtime div {
	width: 45%;
	text-align: center;
}
.punch-main .heading {
	width: 100%;
	font-size: 18px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}
.punch-main button {
	background: var(--color-primary);
	border: none;
	color: white;
	font-size: 16px;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin: 10px 0;
}

.punch-main .bg-red {
	background: red;
	border: none;
	color: white;
	font-size: 16px;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin: 10px 0;
}

.Punchbtn:disabled {
	background: #d3d3d3;
}

.punch-main button:hover {
	background: var(--color-primary) !important;
	border: none !important;
	color: white !important;
	font-size: 16px !important;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin: 10px 0;
}

.punch-main .bg-red:hover {
	background: red !important;
	border: none;
	color: white;
	font-size: 16px;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin: 10px 0;
}

.btn-adjust {
	margin-top: 30px;
	border-radius: 10px;
	height: 40px;
}

.btn-adjust span {
	padding: 0px;
	word-break: auto-phrase !important;
	text-wrap: wrap;
}

.attendance-main .attendance-months {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	gap: 10px;
}

.attendance-main .top-sec {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	align-items: stretch;
}

.attendance-main .top-sec .main-item {
	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	border-radius: 20px;
}


.attendance-main .top-sec .main-item:last-child {
	background-color: transparent;
	box-shadow: none;
}

@media (max-width: 500px) {
	.attendance-main .top-sec .main-item {
		width: 100% !important;
	}
}

.bottom-sec .cont-1 {
	margin-top: 20px;
	display: flex;
	align-items: stretch;
	/* flex-wrap: wrap; */
	gap: 16px;
}

.dot {
	background: #7aff3c;
	border-radius: 50%;
	flex-shrink: 0;
	width: 8px;
	height: 8px;
	margin-left: 10px;
	margin-top: 5px;
}

.attendance-main .month-overview {
	display: flex;
	gap: 50px;
	padding: 10px;
	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
	width: max-content;
	border-radius: 20px;
}

.att-main .att-records {
	background-color: white;
	padding: 20px;
	border-radius: 20px;
}

.att-main .ant-table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
}

.att-main .ant-table-thead > tr > th {
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	font-weight: bold;
	text-align: center;
	padding: 10px;
	font-size: 18px;
}

.att-main .ant-table-tbody > tr > td {
	border: 1px solid #ddd;
	padding: 10px;
	text-align: center;
	font-size: 16px;
}

.att-main .ant-table-tbody > tr:nth-child(even) > td {
	background-color: #eee;
}

.att-main .ant-table-tbody > tr:hover > td {
	background-color: #f0f0f0;
	cursor: pointer;
}

tr.row-not-logged-in td button {
	color: red !important;
	font-weight: 600;
}

.bo {
	background: #f49f20;
}
.status {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 5px;
	flex-shrink: 0;
	margin-top: 5px;
}

.status .present {
	background-color: green;
	padding: 0px 5px;
	border-radius: 10px;
	width: max-content;
}

.status .absent {
	background-color: red;
	padding: 0px 5px;
	border-radius: 10px;
	cursor: pointer;
	width: max-content;
}

.status .holiday {
	background-color: blue;
	padding: 0px 5px;
	border-radius: 10px;
	cursor: pointer;
	width: max-content;
}

.status .late {
	background-color: orange;
	padding: 0px 5px;
	border-radius: 10px;
	width: max-content;
}
.mt-2 {
	margin-top: 20px;
}
.month {
	color: #ffffff;
	text-align: left;
	font-size: 22px;
	text-transform: uppercase;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.month span {
	font-size: 14px;
	margin-left: 5px;
}
.stat {
	color: #ffffff;
	text-align: left;
	font: 400 12px "Inter", sans-serif;
	position: relative;
	width: 44px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.days {
	font-size: 12px;
	font-weight: 600;
	color: white;
}

.attendance-main .working-days {
	background: #eeeeee;
	border-radius: 15px;
	border-width: 0px 0px 40px 0px;
	border-style: solid;
	border-image: linear-gradient(
		90.23deg,
		rgba(17, 90, 136, 1) 0%,
		rgba(56, 177, 255, 0.89) 100%
	);
	border-image-slice: 1;
	width: 200px;
	height: 100px;
	margin-top: 30px;
}

.holidays .item {
	border-radius: 10px;
	border-width: 0px 0px 40px 0px;
	border-style: solid;
	background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
	border-color: #eee;
	border-image-slice: 1;
	width: 180px;
	height: 100px;
}

.attendance-main .item:hover {
	cursor: pointer;
}

.holidays .holidays-main {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 20px;
	gap: 24px;
}

.attendance-main .working-days p {
	color: #000000;
	text-align: left;
	opacity: 0.5;
	font-weight: 600;
	position: relative;
	top: -40px;
	left: 10px;
}
.attendance-main .holidays p {
	color: #000000;
	text-align: left;
	opacity: 0.5;
	position: relative;
	font-weight: 600;
	left: 10px;
	font-size: 18px;
}

.working-days .title {
	color: #2c86c0;
	text-align: left;
	font-size: 24px;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	top: -35px;
	left: 15px;
}

.holidays .title {
	color: white;
	text-align: left;
	font-size: 24px;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	top: 10px;
	left: 15px;
}

.working-days .value {
	color: #ffffff;
	text-align: right;
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	opacity: 0.8;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: 10px;
	top: -20px;
}

.holidays .value {
	color: #000000;
	text-align: right;
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: 12px;
	top: 8px;
}

.holidaylist {
	border-radius: 0 0 20px 20px;
	background: #fff;
	margin-top: -100px;
	/* width: 360px; */
	padding: 20px;
}
.holiday-item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	background: #ececec;
	border-radius: 10px;
	width: 240px;
	padding: 10px;
	margin: 5px;
}
.holiday-name {
	color: #de804b;
	font-size: 19px;
	font-family: Inter;
	text-transform: capitalize;
}

.holiday-date {
	border-radius: 7px;
	background: #de804b;
	padding: 5px;
}

.punch-main {
	background-color: white;
	padding: 20px;
	border-radius: 20px;
}

.punch-main .qt {
	font-size: 16px;
	font-weight: 500;
	color: darkviolet;
}

.punch-main .Punchbtn .req-att-main {
	background-color: white;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
	width: max-content;
	margin: 0 auto;
	border-radius: 10px;
}

.req-att-main .ant-input {
	background-color: #e9e8e8;
	width: 350px;
}

@media (max-width: 768px) {
	.attendance-months {
		max-width: calc(100vw - 40px);
		overflow: auto;
		scrollbar-width: none;
	}
	.attendance-main {
		flex-direction: column;
	}
	.month-overview {
		max-width: calc(100vw - 40px);
		overflow: auto;
		scrollbar-width: none;
	}
	.cont-1 {
		width: calc(100vw - 40px);
	}

	.attendance-main {
		padding-bottom: 10px;
	}
}

@media (max-width: 480px) {
	.holidays .holidays-main {
		margin: 8px;
		gap: 16px;
	}
	.holidays .item {
		border-width: 0px 0px 40px 0px;
		width: 45%;
		height: 90px;
	}
	.holidays .title {
		font-size: 18px;
	}
	.holidays .value {
		font-size: 18px;
	}
}

.brean-reason-form {
	display: flex;
}

.brean-reason-form input {
	width: 200px !important;
}

.attendance-container {
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	max-width: 800px;
	margin: 20px auto;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.attendance-container h1 {
	margin-bottom: 20px;
	color: #333;
	font-size: 24px;
	text-align: center;
}

.attendance-container form {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 20px;
}

.attendance-container form div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.attendance-container form label {
	flex: 1;
	margin-right: 10px;
	color: #555;
}

.attendance-container form input[type="date"] {
	flex: 2;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.attendance-container form button {
	padding: 10px 20px;
	background-color: #1890ff;
	color: white;
	border: none;
	cursor: pointer;
	border-radius: 4px;
	font-size: 16px;
	align-self: center;
}

.attendance-container form button:hover {
	background-color: #40a9ff;
}

.attendance-container .ant-table {
	background-color: white;
}

.attendance-container .ant-table-thead > tr > th {
	background-color: #fafafa;
	font-weight: bold;
	color: #555;
}

.attendance-container .ant-table-tbody > tr > td {
	color: #333;
}

.attendance-container .ant-table-tbody > tr:hover > td {
	background-color: #f1f1f1;
}

.attendance-container .attendance-container .row-not-logged-in {
	background-color: #ffe6e6;
}

.attendance-container .attendance-container .csv-download-button {
	padding: 10px 20px;
	background-color: #52c41a;
	color: white;
	border: none;
	cursor: pointer;
	border-radius: 4px;
	margin-bottom: 20px;
	font-size: 16px;
	align-self: center;
}

.attendance-container .attendance-container .csv-download-button:hover {
	background-color: #73d13d;
}

.attendance-container .ant-modal-content {
	border-radius: 8px;
}

.attendance-container .ant-modal-header {
	background-color: #fafafa;
	border-bottom: 1px solid #e8e8e8;
	border-radius: 8px 8px 0 0;
}

.attendance-container .ant-modal-title {
	color: #333;
	font-size: 20px;
}

.attendance-container .ant-modal-body {
	padding: 20px;
	background-color: #fff;
}

.attendance-container .ant-input {
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 10px;
}

.attendance-container .ant-btn {
	border-radius: 4px;
}

.attendance-container .ant-btn-primary {
	background-color: #1890ff;
	border-color: #1890ff;
}

.attendance-container .ant-btn-primary:hover {
	background-color: #40a9ff;
	border-color: #40a9ff;
}

.currentMonth {
	margin-top: 5px;
	background: linear-gradient(
		157.62deg,
		rgba(73, 175, 250, 1) 0%,
		rgba(31, 127, 217, 1) 48.95833432674408%,
		rgba(0, 163, 255, 0.87) 100%
	);
	border-radius: 21px;
	padding: 15px;
}

.att-main .section-1 {
	display: block;
	background-color: white;
}

.att-btns {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: row;
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
}

.att-btns button {
	height: 40px;
	background-color: var(--color-primary);
}

.previousMonth {
	inset: 0;
	background: linear-gradient(
		-47.19deg,
		rgba(27, 37, 129, 1) 0%,
		rgba(46, 58, 159, 0.79) 62.938129901885986%,
		rgba(9, 27, 188, 0.51) 100%
	);
	border-radius: 28px;
	gap: 7px;

	padding: 15px;
}

.beforepreviousMonth {
	background: linear-gradient(
		126.63deg,
		rgba(43, 53, 141, 1) 0%,
		rgba(204, 93, 173, 0.37) 100%
	);
	border-radius: 24px;

	padding: 15px;
	margin-top: 0px;
}

.cmetrics-main {
	padding: 20px;
	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	border-radius: 20px;
}

.cmetrics-main .item-head {
	width: 100%;
	font-size: 18px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}

.cmetrics-items {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	margin-top: 20px;
}

.cmetrics-main .cmetric-content {
	padding: 20px;
	border-radius: 10px;
	flex: 1;
	min-width: 100px;
}

.cmetrics-main .cmetric-content .ant-statistic-title {
	color: white;
}

@media (max-width: 768px) {
	.attendance-main .top-sec .main-item {
		background-color: #ffffff;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
		border-radius: 20px;
		width: 100%;
	}
}

.attendanceData-main .sec-heading {
	margin: 10px;
	margin-top: 20px;
	width: 100%;
	font-size: 22px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid black;
}

.attendanceData {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: flex-start;
	margin: 20px 32px;
}

.attendanceItem {
	background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
	border-radius: 8px;
	padding: 1rem;
	width: 280px;
	height: 140px;
	color: #000000;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.view-all {
	justify-content: center;
	align-items: center;
}

.view-all a {
	color: #000000;
	font-size: 24px;
	width: 100%;
	text-align: center;
}

.attendanceHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.attendanceDate {
	font-size: 1rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.attendanceStatus {
	padding: 0.2rem 0.5rem;
	border-radius: 12px;
	background-color: #28a745;
	color: #fff;
	text-transform: capitalize;
}

.attendanceData .attendanceStatus.on-time {
	background-color: #28a745;
}

.attendanceData .attendanceStatus.latelogin {
	background-color: orangered;
}

.attendanceData .attendanceStatus.absent {
	background-color: #dc3545;
}

.attendanceData .attendanceStatus.holiday {
	background-color: #0000ff;
}

.attendanceData .attendanceDetails {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
}

.attendanceData .attendanceCheckIn,
.attendanceCheckOut {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.2rem;
}

.attendanceData .label {
	font-size: 0.7rem;
	display: flex;
	align-items: center;
	gap: 0.2rem;
}

.attendanceData .value {
	font-size: 1.1rem;
	font-weight: bold;
}

.attendance-months {
	background-color: #fff;
	padding: 20px;
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}

@media (max-width: 678px) {
	.attendance-months .recharts-wrapper,
	.attendance-months .recharts-legend-wrapper {
		width: 90% !important;
		height: max-content !important;
	}

	.attendance-months .recharts-legend-wrapper {
		position: relative !important;
	}
	.bottom-sec .cont-1 {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 32px;
	}
}

.attendancesummary-main .sec-head {
	margin: 10px;
	width: 100%;
	font-size: 22px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid black;
}

.holidays .sec-head {
	margin: 10px;
	margin-top: 20px;
	width: 100%;
	font-size: 22px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid black;
}
