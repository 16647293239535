.login-main {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 40px);
	background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
	padding: 20px;
}

.login-container {
	width: 900px;
	max-width: 100%;
	height: auto;
	background: #fff;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}

.login-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
}

.left-section {
	padding: 50px;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.left-section h2 {
	margin-bottom: 24px;
	text-align: center;
	font-size: 24px;
	padding: 0;
	font-weight: 600;
}

.right-section {
	background: var(--color-primary);
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.right-section .logo {
	width: 240px;
	margin-bottom: 0px;
}

.right-section .description {
	text-align: center;
	font-size: 18px;
	line-height: 1.5;
	padding: 0 10px;
}

.login-form .inpp {
	border-radius: 5px;
	padding: 10px;
	border: 1px solid #d9d9d9;
}

.forgotpass {
	display: flex;
	justify-content: flex-end;
	margin-top: -10px;
	font-size: 14px;
}

.sub-btn {
	display: flex;
	justify-content: center;
	margin: 20px;
}

.sub-btn button {
	width: 160px;
	height: 40px;
	border-radius: 5px;
	background: var(--color-primary);
	color: #fff;
	border: none;
	padding: 10px 30px;
	font-size: 18px;
	font-weight: 400;
	cursor: pointer;
	transition: background 0.3s ease;
}

.sub-btn button:hover {
	background: #003d99;
}

@media screen and (max-width: 768px) {
	.login-container {
		flex-direction: column;
		height: auto;
	}

	.login-grid {
		grid-template-columns: 1fr;
	}

	.left-section {
		padding: 30px;
		order: 2;
	}

	.right-section {
		order: 1;
		border-radius: 10px 10px 0 0;
	}

	.right-section .description {
		padding: 0 20px;
	}
}

@media screen and (max-width: 480px) {
	.login-main {
		margin: 0px;
		padding: 10px;
		padding-top: 40px;
	}
	.left-section h2 {
		margin-top: -16px;
		font-size: 24px;
	}

	.right-section .logo {
		width: 180px;
		margin: 0;
	}

	.right-section .description {
		font-size: 14px;
		padding: 0;
	}

	.login-form .inpp {
		padding: 8px;
	}

	.sub-btn button {
		padding: 8px 0;
		font-size: 16px;
	}
}
