.payroll-run-modal .choice-selection{
    display: flex;
    flex-direction: row;
    margin: 20px;
    gap: 50px;
    justify-content: center;
}

.payroll-run-modal .choice-selection .ant-select-selector {
    height: 40px;
    border:none;
    background-color: #eee;
    min-width:150px !important;
    width:150px !important;
    display: flex;
    align-items: center;
}

.payroll-run-modal .run-btn{
    display: flex;
    align-items: center;
    margin: 20px;
}

.payroll-run-modal .run-btn button{
    height: 40px;
    margin: 10px auto;
    background-color: var(--color-primary);
    opacity: 0.8;
    border: none;
    color: white;
}


.payroll-run-modal .run-btn button:hover{
    height: 40px;
    margin: 10px auto;
    background-color: var(--color-primary);
    border: none;
    opacity: 1;
    color: white;
}