.working-breaktimes {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.todays-insights .punch-item {
	display: flex;
	flex-direction: column;
	border: 2px solid #ddd;
	padding: 20px;
	margin-top: 20px;
	border-radius: 10px;
	width: 200px;
}

.punch-out-time .punch-item {
	display: flex;
	flex-direction: column;
	border: 2px solid #ddd;
	padding: 20px;
	margin-top: 20px;
	border-radius: 10px;
	width: 100%;
}

.punch-out-time .punch-item .item-title {
	font-size: 14px;
}

.punch-out-time .punch-item .item-value {
	font-size: 22px;
	color: var(--color-primary);
	font-weight: 600;
}

.punch-item .ant-progress-text {
	width: 99% !important;
}

.todays-insights .punch-item .item-title {
	font-size: 14px;
}

.todays-insights {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 16px;
}

.todays-insights .punch-item .item-value {
	font-size: 22px;
	color: var(--color-primary);
	font-weight: 600;
}

.punch-action-btns {
	display: flex;
}

.punch-action-btns button {
	flex: 1;
}

.punch-action-btns-inner {
	display: flex;
	gap: 20px;
}

.punch-action-btns-inner div {
	flex: 1;
}

.punch-main button {
	width: 100% !important;
	height: 40px !important;
}

@media (max-width: 468px) {
	.todays-insights .punch-item {
		display: flex;
		flex-direction: column;
		border: 2px solid #ddd;
		padding: 10px;
		width: 160px;
		margin-top: 20px;
		border-radius: 10px;
	}
}
@media (max-width: 420px) {
}
