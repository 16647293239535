.add-employee-cont input {
	height: 40px !important;
	width: 350px !important;
	background: #eee;
}

.employeelist-main {
	background-color: white;
	padding: 20px;
	border-radius: 20px;
}

.add-employee-cont {
	margin: 20px;
}

.add-employee-cont .ant-picker-input {
	height: 35px;
	width: 330px;
}

.add-employee-cont .add-emp-form {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

/* .add-employee-cont .add-emp-form button {
	height: 45px;
	border: 2px solid var(--color-primary);
	background-color: #eee;
	color: var(--color-primary);
	font-size: 18px;
	font-weight: 600;
}

.add-employee-cont .add-emp-form button:hover {
	background-color: var(--color-primary);
	color: white;
} */

.basic-main {
	padding-inline: 20px;
}

.basic-main .group {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

.basic-main .detail {
	background-color: #f0f0f0;
	margin: 10px;
	padding: 12px 24px;
	font-size: 16px;
	width: 100%;
	border-radius: 10px;
	font-weight: 400;
}

.basic-main .detail span {
	font-weight: 600;
}

.documents-main {
	background-color: white;
	padding-inline: 20px;
	border-radius: 20px;
}

.documents-main .group {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.documents-main .document-item {
	height: 350px;
	width: 270px;
	background-color: #eee;
}

.documents-main .document-item img {
	height: 250px;
	width: 220px;
}

.relation-table {
	overflow: auto;
}

.toolbar-wrapper {
	position: fixed;
	bottom: 32px;
	left: 50%;
	padding: 0px 24px;
	color: #fff;
	font-size: 20px;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
	padding: 12px;
	cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
	cursor: not-allowed;
	opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
	opacity: 0.3;
}

.employee-search {
	display: flex;
	justify-content: end;
	margin-bottom: 10px;
}
.employee-search input {
	width: 200px;
	height: 40px;
}

.employee-table.ant-table-wrapper .ant-table td {
	border-bottom: 1px solid black;
	margin: 2px;
	cursor: pointer;
}

@media (max-width: 768px) {
	.add-employee-cont input {
		height: 40px !important;
		width: 250px !important;
		background: #eee;
	}

	.add-employee-cont {
		margin: 20px;
	}
	.add-employee-cont .ant-picker-input {
		height: 35px;
		width: 230px;
	}
	.add-employee-cont .ant-select-selector {
		height: 35px;
		width: 250px !important;
	}
	.documents-main .group {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.documents-main {
		background-color: white;
		padding: 5px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.documents-main .document-item {
		height: 300px;
		width: 230px;
		background-color: #eee;
	}
	.basic-main {
		padding-inline: 0px;
	}
	.basic-main .group {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
	.ant-table-wrapper {
		overflow: auto;
		scrollbar-width: none;
	}
	.basic-main .detail {
		background-color: #eee;
		margin: 0px;
		margin-bottom: 10px;
		padding: 10px;
		font-size: 14px;
	}

	.documents-main .document-item img {
		height: 200px;
		width: 170px;
	}
}
