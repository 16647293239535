.exit-interview-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .exit-interview-form label {
    font-weight: bold;
  }
  
  .exit-interview-form .ant-form-item {
    margin-bottom: 25px;
  }
  
  .exit-interview-form .ant-checkbox-group-item,
  .exit-interview-form .ant-radio-group {
    display: block;
    margin-top: 15px;
  }
  
  .exit-interview-form .ant-radio-button-wrapper {
    margin-right: 15px;
  }
  
  .exit-interview-form .ant-radio-group-vertical .ant-radio-button-wrapper {
    display: block;
  }
  
  .exit-interview-form .ant-radio-button-wrapper:hover,
  .exit-interview-form .ant-checkbox-wrapper:hover {
    color: #1890ff;
  }
  
  .exit-interview-form .ant-btn-primary {
    margin-top: 30px;
    background-color: #1890ff;
    border-color: #1890ff;
  }
  
  .exit-interview-form .ant-btn-primary:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  .exit-interview-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .exit-interview-form label {
    font-weight: bold;
  }
  
  .exit-interview-form .ant-form-item {
    margin-bottom: 25px;
  }
  
  .exit-interview-form .ant-checkbox-group-item,
  .exit-interview-form .ant-radio-group {
    display: block;
    margin-top: 15px;
  }
  
  .exit-interview-form .ant-radio-button-wrapper {
    margin-right: 15px;
  }
  
  .exit-interview-form .ant-radio-group-vertical .ant-radio-button-wrapper {
    display: block;
  }
  
  .exit-interview-form .ant-radio-button-wrapper:hover,
  .exit-interview-form .ant-checkbox-wrapper:hover {
    color: #1890ff;
  }
  
  .exit-interview-form .ant-btn-primary {
    margin-top: 30px;
    background-color: #1890ff;
    border-color: #1890ff;
  }
  
  .exit-interview-form .ant-btn-primary:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
    