.org-structure-main .roles,
.org-structure-main .departments {
	margin-top: 10px;
	background-color: white;
	border-radius: 10px;
	padding: 20px;
}

.org-structure-main{
	background-color: white;
	padding: 20px;
	border-radius: 20px;
	margin-top: 20px;
}
.empdata-table{
	width:80%;
	margin:20px auto;

}
.empdata-table h1{
	text-align: center;
}
.roles-form .ant-form-item-control-input-content {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.departments-form .sub-btn {
	height: 40px;
	font-size: 16px;
	background-color: var(--color-primary);
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.departments-form .department-listing {
	display: flex;
	align-items: center;
	font-size: 16px;
	gap: 15px;
}

.org-structure-main .desdeps {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 50px;
}

.departments-form .department-listing button {
	color: red;
	font-weight: 600;
	padding: 0px;
	font-size: 12px;
}

.roles-form label {
	font-size: 16px;
}

.roles-form button {
	height: 40px;
	font-size: 16px;
	background-color: var(--color-primary);
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.roles-container{
	height: 200px;
    width: 200px;
    background-color: blue;
    border-radius: 10px;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-size: 25px;
}

.roles-container:hover{
	transform: scale(1.05);
    cursor: pointer;
}

.functionalities{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
}


@media (max-width: 768px) {
	.org-structure-main .desdeps {
		margin-top: 10px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 10px;
	}
    .org-structure-main .ant-table-wrapper{
        overflow: auto;
    }
}
