.header-only-content{
    position: absolute;
    top: 80px;
    width: 100vw;
    height: calc(100vh - 80px);
    background: #ddd;
    padding: 20px;
    overflow: auto;
}

@media (max-width: 768px){
    .header-only-content{
        top: 80px;
        height: calc(100vh - 80px);
    }
}