.resignation-view {
	padding: 20px;
	border-radius: 8px;
}

.resignation-header {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}
.resignation-header button{
    background-color: var(--color-primary);
    color: white;
}
.resignation-details {
	background: #ffffff;
	padding: 20px;
	border-radius: 4px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.resignation-form {
	max-width: 500px;
	margin: auto;
}

.resignation-textarea {
	border: none;
	resize: none;
	background: #f0f2f5;
	color: #333;
}
