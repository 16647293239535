.policy-container {
	padding: 30px;
	background-color: #ffffff;
	border-radius: 12px;
	box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
	max-width: 100%;
	margin: 20px 0;
	overflow: hidden;
}

.policy-container .ant-tabs {
	margin-bottom: 24px;
}

.policy-container .ant-tabs-nav {
	border-bottom: 1px solid #e8e8e8;
	border-radius: 12px;
	overflow: hidden;
}

.policy-container .ant-tabs-tab {
	font-weight: 500;
	font-size: 16px;
	padding: 8px;
	transition: background-color 0.3s, color 0.3s;
	border-radius: 0;
}

@media (max-width: 1200px) {
	.policy-container {
		padding: 20px;
	}
}

@media (max-width: 768px) {
	.policy-container {
		padding: 15px;
	}
}

@media (max-width: 480px) {
	.policy-container {
		padding: 10px;
	}
}

.leave-policy-main {
	padding: 10px;
	max-width: 100%;
	margin: 0;
	background-color: #fff;
}

.leave-policy-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.leave-policy-card {
	border-radius: 8px;
	background-color: #ffffff;
	padding: 20px;
}

.leave-policy-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.leave-policy-item {
	flex: 1 1 calc(30% - 20px);
	margin: 10px;
	padding: 15px;
	border: 1px solid #e0e0e0;
	border-radius: 6px;
	background-color: #f9f9f9;
	transition: transform 0.3s, box-shadow 0.3s;
}

.leave-policy-item:hover {
	transform: translateY(-1px);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
	cursor: pointer;
}

.leave-policy-card p {
	font-size: 16px;
	color: #333;
	line-height: 1.5;
	margin: 10px 0;
}

.leave-policy-label {
	font-weight: 600;
	color: #555;
}

.leave-policy-value {
	margin-left: 5px;
	font-weight: 500;
	color: #333;
}

.leave-policy-button {
	background-color: #1890ff;
	color: #ffffff;
	border: none;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
	transition: background-color 0.3s, transform 0.3s;
}

.leave-policy-button:hover {
	background-color: #0056b3;
	transform: scale(1.05);
}

.leave-policy-modal {
	border-radius: 12px;
	padding: 20px;
	background-color: #fff;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.leave-policy-modal-title {
	font-size: 20px;
	color: #333;
}

.leave-policy-modal-footer {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.leave-policy-form-item {
	margin-bottom: 16px;
}

.leave-policy-form-item-label {
	font-weight: 600;
	color: #333;
}

.leave-policy-input {
	border-radius: 4px;
	border: 1px solid #d9d9d9;
	padding: 10px;
	width: 100%;
	transition: border-color 0.3s, box-shadow 0.3s;
}

.leave-policy-input:hover {
	border-color: #1890ff;
}

.leave-policy-input:focus {
	border-color: #1890ff;
	box-shadow: 0 0 5px rgba(24, 144, 255, 0.2);
}

.leave-policy-no-policy {
	text-align: center;
	margin-top: 50px;
}

@media (max-width: 768px) {
	.leave-policy-item {
		flex: 1 1 calc(45% - 20px);
	}

	.leave-policy-button {
		width: 100%;
		padding: 12px;
	}
}

@media (max-width: 480px) {
	.leave-policy-card p {
		font-size: 14px;
	}

	.leave-policy-item {
		flex: 1 1 calc(100% - 20px);
	}
}

.ant-modal {
	max-width: 80vw;
}

.leave-policy-form {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.leave-policy-form .ant-form-item {
	flex: 1 1 calc(50% - 16px);
}

@media (max-width: 768px) {
	.leave-policy-form .ant-form-item {
		flex: 1 1 100%;
	}
}



.payrollpolicy-card {
	border-radius: 8px;
	
	background-color: white;
	margin-bottom: 20px;
	padding: 20px;
}

.payrollpolicy-card p {
	font-size: 16px;
}

.payrollpolicy-card p span {
	font-weight: bold;
	margin-right: 10px;
}

.payrollpolicy-empty {
	font-size: 18px;
	color: #888;
	margin-bottom: 10px;
}

.ant-modal-title {
	font-size: 18px;
	color: #333;
}

.payrollpolicy-main .ant-btn-primary {
	background-color: #4caf50;
	border-color: #4caf50;
}

.payrollpolicy-main .ant-btn-primary:hover {
	background-color: #45a049;
	border-color: #45a049;
}

.payrollpolicy-main .ant-btn {
	margin-right: 8px;
}
