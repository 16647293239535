.child-details-cont .child-details {
	/* height: 300px; */
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.child-details-cont .second-section {
	/* height: 300px; */
	display: grid;
	grid-template-columns: 1fr 2fr;
	margin: 0px;
	margin-top: 20px;
}

.child-details-cont .child-details .child-det {
	padding: 30px;
	background: white;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
	border-radius: 20px;
}

.child-details-cont .child-details .child-det .child-info div {
	font-size: 18px;
	margin: 10px;
	font-weight: 600;
	text-transform: capitalize;
}
.child-details-cont .child-details .child-det .child-info div span {
	font-weight: 400;
	opacity: 0.5;
}

.child-details-cont .child-info {
	display: grid;
	grid-template-columns: 1fr;
}

.child-details-cont .info {
	display: grid;
	grid-template-columns: 1fr;
}

.child-details-cont .section-head {
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	padding-bottom: 5px;
	border-bottom: 2px solid #ddd;
}

.child-details-cont .child-details .con-det {
	padding: 30px;
	background: white;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
	margin-left: 25px;
	border-radius: 20px;
}

.child-details-cont .con-det .info div {
	font-size: 18px;
	font-weight: 600;
	margin: 10px;
}

.child-details-cont .con-det .info div span {
	font-weight: 400;
	opacity: 0.5;
}

.child-details-cont .bussiness-owner-det .info div {
	font-size: 18px;
	font-weight: 600;
	margin: 10px;
}
.child-details-cont .bussiness-owner-det .info {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.child-details-cont .bussiness-owner-det .info div span {
	font-weight: 400;
	opacity: 0.5;
}

.child-details-cont .bussiness-owner-det {
	padding: 30px;
	background: white;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px; */
	border-radius: 20px;
	margin-top: 20px;
}

.bussiness-owner-det .assign-bo {
	text-align: center;
	margin: 20px;
	font-size: 20px;
	font-weight: 600;
}

.bussiness-owner-det .assign-bo button {
	margin: 10px;
	font-size: 20px;
	background: #ffffff;
	border: 2px solid var(--color-primary);
	border-radius: 10px;
	cursor: pointer;
	padding: 10px;
}

.bussiness-owner-det .assign-bo button:hover {
	margin: 10px;
	font-size: 20px;
	background: var(--color-primary);
	border: 2px solid var(--color-primary);
	color: #ffffff;
	border-radius: 10px;
	cursor: pointer;
}

.child-cont {
	display: flex;
	gap: 20px;
}

.child-cont .child {
	width: 150px;
	margin: 20px;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: white;
	padding: 20px;
	font-size: 18px;
	font-weight: 600;
	word-break: keep-all;
	border: 2px solid var(--color-primary);
	border-radius: 20px;
	color: black;
	/* box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px; */
}

.child-cont .child:hover {
	background-color: var(--color-primary);
	color: white !important;
}

.child-details-cont .go-back {
	margin-top: -10px;
	margin-bottom: 10px;
}

.child-details-cont .go-back button {
	font-weight: 700;
	color: var(--color-primary);
}

.add-child-container {
	margin-top: 20px;
}

.add-child-container .add-child-form {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.add-child-container .add-child-form input {
	width: 300px !important;
	height: 45px !important;
	background-color: #eee;
}
.add-child-container .add-child-form .ant-picker {
	height: 45px;
	width: 300px !important;
	background-color: #eee !important;
}

.add-child-container .add-child-form .ant-picker-input {
	height: 45px;
	width: 280px !important;
}
.add-child-container .add-child-form button {
	height: 45px;
	border: 2px solid var(--color-primary);
	background-color: #eee;
	color: var(--color-primary);
	font-size: 18px;
	font-weight: 600;
}

.add-child-container .add-child-form button:hover {
	background-color: var(--color-primary);
	color: white;
}
@media (max-width: 500px) {
	.child-details-cont .child-details {
		display: block;
	}
	.child-details-cont .second-section {
		display: block;
		margin-top: 20px;
	}

	.child-details-cont .child-details .child-det {
		padding: 20px;
		border-radius: 15px;
	}
	.child-details-cont .child-details .child-det .child-info div {
		font-size: 16px;
		margin: 10px;
		font-weight: 600;
		text-transform: capitalize;
	}
	.child-details-cont .child-details .con-det {
		padding: 20px;
		border-radius: 15px;
		margin-left: 0px;
		margin-top: 20px;
	}
	.child-details-cont .child-info {
		display: grid;
		grid-template-columns: 1fr;
	}
	.child-details-cont .plan-details .info div {
		font-size: 16px;
	}
	.child-details-cont .con-det .info div {
		font-size: 16px;
	}
	.child-cont .child {
		width: 100px;
		height: 100px;
		margin: 10px;
		overflow: hidden;
		line-break: loose;
		padding: 10px;
		font-size: 12px;
	}
    .child-details-cont .bussiness-owner-det .info{
        grid-template-columns: 1fr;
    }
}
