.hr-grievances {
	background-color: white;
	padding: 20px;
	border-radius: 20px;
}

.hr-grievances h3 {
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	padding-bottom: 5px;
	border-bottom: 2px solid #ddd;
}

.grievance-details-main .details {
	background-color: white;
	padding: 20px;
	border-radius: 20px;
	width: 50%;
}

.grievance-details-main .details .item p {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 18px;
}

.grievance-details-main .details .item p span {
	font-weight: 600;
}

.grievance-details-main .comments-list-header {
	font-size: 18px;
	font-weight: 600;
	display: flex;
	justify-content: space-between;
}

.grievance-details-main {
	margin: 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: start;
	gap: 50px;
}

.grievance-details-main .comments {
	background-color: white;
	padding: 20px;
	border-radius: 20px;
	width: 40%;
}

@media (max-width: 767px) {
	.grievance-details-main .details {
		width: calc(100vw - 50px);
        padding: 10px;
	}
	.grievance-details-main {
		margin: 0px;
		gap: 20px;
	}
	.grievance-details-main .details .item p {
		font-size: 14px;
	}
	.grievance-details-main .comments {
		width: calc(100vw - 50px);
	}
	.grievance-details-main .comments-list-header {
		font-size: 16px;
		font-weight: 600;
	}
	.comments-list-header button {
		margin-top: -5px;
	}
	
}
