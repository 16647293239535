.leave-main .section-1 {
	display: flex;
	justify-content: start;
	align-items: center;
	background: none;
	gap: 50px;
	padding: 0px;
}

.leave-main .section-2 {
	margin-bottom: 20px;
	display: flex;
	gap: 32px;
	flex-wrap: wrap-reverse;
}

.leave-form-cont {
	background-color: white;
	padding: 20px;
	border-radius: 10px;
	width: max-content;
	/* flex: 1; */
}

.leave-form .leave-input {
	height: 40px;
	background-color: #eee;
	border-radius: 0px;
}

.leave-form .input-group {
	display: flex;
	gap: 20px;
	width: 100%;
}

.leave-form .leave-input.ta {
	height: 80px;
}

.leave-form .ant-form-item .ant-form-item-control-input-content {
	width: 250px;
	border-radius: 0;
}

.leave-form .ant-form-item {
	max-width: 520px;
}

.leave-form button {
	width: 160px;
	height: 40px;
	float: right;
	font-size: 16px;
	background: green;
	opacity: 0.7;
}

.leave-form button:hover {
	background: darkgreen !important;
	opacity: 1;
}

.leave-form .ant-form-item .ant-select-selector {
	height: 40px;
	width: 100%;
	max-width: 520px;
	background-color: #eee;
	border-radius: 0;
	min-width: 0;
}

@media (max-width: 580px) {
	.leave-form div {
		display: block;
		gap: 20px;
	}
	.leave-form .ant-form-item .ant-form-item-control-input-content {
		width: 100%;
	}
	.leave-form .ant-form-item .ant-select-selector {
		width: 100%;
	}
}

.opt-holiday-main {
	background-color: white;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
	width: max-content;
	border-radius: 10px;
}

.dashboard-container {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	align-items: stretch;
}

.leave-summary-card,
.leaves-breakdown-card,
.leave-requests-card {
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	height: 100%;
	display: flex;
	flex-direction: column;
}

.leave-requests-card {
	/* padding-right: 10px; */
}

.leave-section {
	margin-bottom: 0px;
}

.leave-section h4 {
	margin-bottom: -10px;
	color: #fff;
}

.leave-section .ant-card-body {
	padding: 0px !important;
}

.leave-list {
	list-style-type: none;
	padding: 0;
}

.leave-list li {
	margin-bottom: -1px;
	color: rgba(255, 255, 255, 0.86);
}

.leave-list li::before {
	content: "• ";
	color: #0073e6;
}
.dashboard-item {
	flex: 1;
}
@media (max-width: 768px) {
	.dashboard-item {
		width: calc(100vw - 40px);
	}
}

.card1 {
	width: 219px;
	height: 178px;
	border-radius: 21px;
}

.bgp {
	background: linear-gradient(
		135.39deg,
		#4970fa -3.66%,
		#183dbf 34.52%,
		rgba(166, 124, 255, 0.87) 98.98%
	);
}

.cardHead {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #ffffff;
}

.pb {
	margin-top: 20px;
	border-radius: 20px;
}

.card2 {
	width: 186px;
	height: 125px;
	background: linear-gradient(
		312.81deg,
		#3eedf9 -3.04%,
		#4f66ec 53.17%,
		#8662ed 86.26%
	);
	border-radius: 21px;
}

.lbl {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 0px;
	width: 130px;
	height: 23px;
	margin-bottom: 5px;

	background: rgba(172, 160, 240, 0.5);
	border-radius: 7px;
}

.card3 {
	width: 186px;
	height: 72px;
	background: linear-gradient(312.81deg, #4f66ec 5.79%, #33b9d7 86.26%);
	border-radius: 21px;
	margin-top: 10px;
}

.statustext {
	position: relative;
	width: 103px;
	height: 20px;
	top: -15px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 17px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	color: #ffffff;
	opacity: 0.9;
}
.stats {
	position: relative;
	width: 103px;
	height: 20px;
	top: -10px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 17px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	color: black;
	opacity: 0.9;
}

.m-t-1 {
	margin-top: -10px;
}

.mr-1 {
	margin-right: 15px;
}

.ml-2 {
	margin-left: 20px;
}

.wt {
	width: 70px;
	height: 20px;
	margin-top: 30px;
	font-style: normal;
	font-weight: 400;
	font-size: 19px;
	line-height: 15px;
	display: flex;
	align-items: center;
	text-transform: capitalize;

	color: #ffffff;
}

.white {
	color: #ffffff;
}

.fr {
	position: absolute;
	width: 59px;
	height: 29px;
	left: 149px;
	bottom: 25px;
	background: rgba(125, 139, 255, 0.5);
	border-radius: 10px;
}
.fr a {
	width: 28px;
	height: 15px;
	font-style: normal;
	font-weight: 400;
	font-size: 19px;
	margin: 10px;
	color: #ffffff;
}

@media (max-width: 768px) {
	.fcon {
		background-color: white;
		display: grid;
		border-radius: 20px;
		place-content: left;
		width: calc(100vw - 50px);
		padding: 20px;
	}
	.sin {
		width: calc(100vw - 100px);
		height: 30px;
		margin-bottom: 50px;
	}

	.fcon input {
		width: calc(100vw - 150px) !important;
		height: 40px !important;
	}

	.fcon select {
		height: 40px !important;
	}

	.fcon .ant-select-selector {
		width: calc(100vw - 100px) !important;
		height: 40px !important;
	}

	.fcon .text-area {
		width: calc(100vw - 100px) !important;
	}
	.fcon .submit-btn {
		width: calc(100vw - 100px);
		height: 50px;
		font-size: 20px;
		position: relative;
		left: 0px;
		top: 0px;
	}
	.leave-main .section-1 {
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: flex-start;
		gap: 10px;
	}
}

.leave-form-cont {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
}

.leave-form-cont .sec-head {
	margin: 10px;
	width: 100%;
	font-size: 22px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid black;
}

.react-calendar {
	width: 400px !important;
	background-color: #fff !important;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px !important;
	border: none !important;
	border-radius: 10px;
	line-height: 1.5 !important;
	font-size: 18px !important;
	flex: 1 !important;
}

.react-calendar__navigation button {
	font-size: 18px !important;
}

@media (max-width: 768px) {
	.react-calendar {
		width: 100% !important;
		flex: 1 !important;
	}
}

@media (max-width: 600px) {
	.leave-form .input-group {
		display: block;
	}
	.leave-form-cont {
		width: 100%;
	}
}

.leave-form .leave-note {
	font-size: 12px;
	margin-top: -16px;
	color: orangered;
}

.leave-main .section-1 .recharts-wrapper,
.leave-main .section-1 .recharts-legend-wrapper {
	width: 100% !important;
	height: max-content !important;
}

.leave-main .section-1 .sec-head {
	margin: 10px;
	width: 100%;
	font-size: 22px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid black;
}
.leave-main .section-1 .sec-head.white-col {
	color: white;
}

.tabcont {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
	padding: 20px;
	border-radius: 20px;
	background-color: white;
}

.tabcont .sec-head {
	margin: 10px;
	width: 100%;
	font-size: 22px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid black;
}

.tabcont .ant-table {
	width: 100%;
	overflow: auto;
	scrollbar-width: none;
}

.tabcont .ant-table-content {
	font-size: 12px;
}

@media (max-width: 600px) {
	.leave-main .section-1 .leave-requests-card .recharts-wrapper,
	.leave-main .section-1 .leave-requests-card .recharts-legend-wrapper {
		width: 80% !important;
	}
}

@media (max-width: 1280px) {
	.leave-form-cont {
		flex: 1;
	}
}

.leave-expanded-row {
	/* width: 80%; */
}

.leave-expanded-row .items-group {
	display: flex;
	gap: 24px;
}

.leave-expanded-row .item pre {
	margin: 0px;
	white-space: break-spaces;
	text-align: justify;
}

.leave-expanded-row .item {
	flex: 1;
	width: 100%;
	display: flex;
	gap: 8px;
	background-color: #f0f0f0;
	padding: 12px 16px;
	border-radius: 5px;
	margin-block: 10px;
}

.leave-expanded-row .item-label {
	font-weight: 600;
	font-size: 14px;
	text-transform: capitalize;
}

.leave-expanded-row .item-value {
	text-transform: capitalize;
	font-size: 14px;
	opacity: 0.8;
}

@media (max-width: 768px) {
	.leave-expanded-row .items-group {
		display: block;
	}
	.leave-expanded-row .item {
		display: block;
		width: 100%;
	}
}
