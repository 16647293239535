.flipClock {
	display: flex;
	gap: 20px;

}

.flipclock-main {
	border-radius: 20px;
	padding: 20px;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
	margin-bottom: 20px;
}

.flipclock-main .qt {
	font-size: 16px;
	font-weight: 500;
	font-style: italic;
	color: darkviolet;
}

.flipclock-main .current-date {
	font-size: 18px;
	border-bottom: 1px solid #ddd;
	margin-block: 15px;
	padding-bottom: 10px;
}

.flipUnitContainer {
	display: block;
	position: relative;
	width: 140px;
	height: 120px;
	perspective-origin: 50% 50%;
	perspective: 300px;
	background-color: var(--color-primary);
	border-radius: 3px;
}

.upperCard,
.lowerCard {
	display: flex;
	position: relative;
	justify-content: center;
	width: 100%;
	height: 50%;
	overflow: hidden;
}

.upperCard {
	align-items: flex-end;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.upperCard span,
.lowerCard span {
	font-size: 4rem;
	font-weight: 500;
	color: white;
}

.upperCard span {
	transform: translateY(50%);
}

.lowerCard {
	align-items: flex-start;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.lowerCard span {
	transform: translateY(-50%);
}

.flipCard {
	display: flex;
	justify-content: center;
	position: absolute;
	left: 0;
	width: 100%;
	height: 50%;
	overflow: hidden;
	backface-visibility: hidden;
}

.flipCard span {
	font-size: 4rem;
	font-weight: 500;
	color: white;
}

.flipCard.unfold {
	top: 50%;
	align-items: flex-start;
	transform-origin: 50% 0%;
	transform: rotateX(180deg);
	background-color: var(--color-primary);
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	border-top: 0.5px solid #ddd;
}

.flipCard.fold {
	top: 0%;
	align-items: flex-end;
	transform-origin: 50% 100%;
	transform: rotateX(0deg);
	background-color: var(--color-primary);
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border-bottom: 0.5px solid #ddd;
}

.flipCard.fold span {
	transform: translateY(50%);
}

.flipCard.unfold span {
	transform: translateY(-50%);
}

.fold {
	animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal
		forwards;
	transform-style: preserve-3d;
}

.unfold {
	animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal
		forwards;
	transform-style: preserve-3d;
}

@media (max-width: 500px) {
	.flipCard span {
		font-size: 3rem;
		font-weight: 500;
		color: white;
	}
	.upperCard span,
	.lowerCard span {
		font-size: 3rem;
		font-weight: 500;
		color: white;
	}
	.flipUnitContainer {
		width: 100px;
		height: 100px;
	}
}

@keyframes fold {
	from {
		transform: rotateX(0deg);
	}
	to {
		transform: rotateX(-180deg);
	}
}

@keyframes unfold {
	from {
		transform: rotateX(180deg);
	}
	to {
		transform: rotateX(0deg);
	}
}

.clock {
	margin: 30px;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	position: relative;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;	font-size: 24px;
	color: #444;
	text-align: center;
}

.clock::after {
	background: #aaa;
	content: "";
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 2px solid #fff;
}

.hour_hand {
	position: absolute;
	width: 5px;
	height: 40px;
	background: #222;
	top: 30%;
	left: 49%;
	transform-origin: bottom;
}

.min_hand {
	position: absolute;
	width: 4px;
	height: 55px;
	background: #444;
	top: 22%;
	left: 49%;
	transform-origin: bottom;
}

.sec_hand {
	position: absolute;
	width: 2px;
	height: 60px;
	background: red;
	top: 20%;
	left: 50%;
	transform-origin: bottom;
}

.clock span {
	position: absolute;
	font-weight: 700;
	font-size: 14px;
}

.twelve {
	top: 10px;
	left: 46%;
}

.one {
	top: 10%;
	right: 26%;
}

.eleven {
	top: 10%;
	left: 26%;
}

.two {
	top: 25%;
	right: 10%;
}

.three {
	right: 10px;
	top: 46%;
}

.four {
	right: 30px;
	top: 67%;
}

.five {
	right: 60px;
	top: 80%;
}

.six {
	bottom: 10px;
	left: 50%;
}

.seven {
	left: 60px;
	top: 82%;
}

.eight {
	left: 30px;
	top: 67%;
}

.nine {
	left: 10px;
	top: 46%;
}

.ten {
	top: 25%;
	left: 10%;
}
