.emp-data-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 120px);
}

.emp-data-card {
	max-width: 500px;
	width: 100%;
	padding: 30px;
	border-radius: 12px;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.emp-data-card:hover {
	box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.emp-data-title {
	font-size: 28px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 25px;
	color: #333;
	letter-spacing: 0.5px;
}

.emp-data-form-group {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.emp-data-label {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 8px;
	color: #555;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.emp-data-form-group .ant-select-selector {
	border-radius: 8px !important;
	padding: 8px 12px !important;
	border-color: #d9d9d9 !important;
	transition: border-color 0.3s;
}

.emp-data-form-group .ant-select-selector:hover {
	border-color: #1890ff !important;
}

.emp-data-select {
	width: 100%;
}

.emp-data-button {
	width: 100%;
	margin-top: 30px;
	height: 45px;
	background-color: var(--color-primary);
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	border-radius: 8px;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.emp-data-button:hover {
	background-color: var(--color-primary);
	box-shadow: 0 8px 16px rgba(24, 144, 255, 0.3);
}

@media (max-width: 768px) {
	.emp-data-card {
		padding: 20px;
		max-width: 100%;
	}

	.emp-data-title {
		font-size: 24px;
	}

	.emp-data-button {
		font-size: 16px;
		height: 40px;
	}
}




.empdata-rm-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.empdata-rm-title {
    font-size: 24px;
    font-weight: 500;
}

.empdata-rm-table-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.empdata-rm-table-container .ant-table{
    overflow: auto;
}

.empdata-rm-table {
    width: 100%;
}
