body {
	margin: 0px;
	padding: 0 !important;
	height: 100vh;
	width: 100vw;
	overflow: auto;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

:root {
	--font-family-primary: "Mulish", sans-serif;
	--font-family-secondary: "lexend", sans-serif;
	--color-primary: #131842;
	--color-white:#FFFFFF;
	--color-secondary: #4c49ed;
	--color-bg: #F5F7FA;
	--color-logo-babypink: #f9b2bc;
	--color-logo-blue: #56a8fd;
	--color-logo-purple: #a16afe;
	--color-logo-pink: #d142f5;
	--color-logo-lightpink: #f46ebe;
}

.gradient-1 {
	background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);
}
.gradient-2 {
	background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
}
.gradient-3 {
	background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
}
.gradient-4 {
  background-image: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
}
.gradient-5 {
background-image: linear-gradient(-225deg, #3D4E81 0%, #5753C9 48%, #6E7FF3 100%);}
.gradient-6 {
	background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
}
.gradient-7 {
	background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
}
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	/* background: var(--color-primary); */
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: var(--color-primary);
}

* {
	font-family: "Poppins", sans-serif;
}
