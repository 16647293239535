.search input {
	height: 40px;
	margin: 10px;
	border: 2px solid var(--color-primary);
}

.talent-manager-home{
	background-color: white;
	padding: 20px;
	border-radius: 20px;
	margin-top: 20px;
}
