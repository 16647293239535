.loader-teacher{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.loader-text{
    font-size: 20px;
    font-family: var(--font-family-title);
    margin-top: -15px;
}

.loader-image{
    height: 150px;
    width: auto;
}