.cont {
	background-color: white;
	display: grid;
	border-radius: 30px;
	place-content: center;
}

.stepcontt {
	padding: 75px 100px 50px 100px;
}

.us {
	padding: 50px;
}

.fc {
	width: 400px;
}

.f {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.m-50 {
	margin: 30px;
}

.bt {
	margin: 10px;
	display: flex;
	justify-content: right;
	align-items: flex-end;
	margin-top: 100px;
}

.inp {
	background-color: #e9e8e8;
	width: 350px;
	height: 40px;
}

.bgg {
	margin: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.m-10 {
	margin: 10px;
}


.ant-select-suffix {
	display: none;
	font-size: 15px;
	color: #080000;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	position: relative;
	background-color: #e9e8e8;
	border: 1px solid #d9d9d9;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	min-width: 350px;
	height: 40px;
}

@media (max-width: 767px) {
	.f {
		display: grid;
		grid-template-columns: 1fr;
	}
	
	.stepcontt {
		padding: 10px;
	}
	.stepcontt .ant-steps.ant-steps-vertical {
		display: flex;
		flex-direction: row !important;
	}
	.m-50 {
		margin: 0px 30px;
	}
	.bt {
		margin: 10px;
		align-items: center;
		justify-content: center;
	}
}
